import { Box, Drawer, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CustomThumbUpload from '../../../Components/CustomFileUpload/UploadImage';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import Constant from '../../../Utils/Constant';
import JoditEditor from 'jodit-react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Skeleton from 'react-loading-skeleton';
import CustomDropDown from '../../../Components/CustomDropDown/CustomDropDown';
import ChipMultiSelect from '../../../Components/ChipMultiSelect/ChipMultiSelect';
import { border } from '@cloudinary/url-gen/qualifiers/background';
const AddBlog = () => {
    const editor = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedThumb, setSelectedThumb] = useState(null);
    const [selectedThumbFile, setSelectedThumbFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [typeCheck, setTypeCheck] = useState(false);
    const [editBlogId, setEditBlogId] = useState(null);
    const [blogDetails, setBlogDetails] = useState({});
    const [editBlogElementId, setEditBlogElementId] = useState(null);
    const [mediaFile, setMediaFile] = useState(null);
    const [mediaFilePrev, setMediaFilePrev] = useState(null);
    const [mediaFileModel, setMediaFileModel] = useState(false);
    const [mediaBlogDetails, setMediaBlogDetails] = useState([]);
    const [blogCategoryList, setBlogCategoryList] = useState([]);
    useEffect(() => {
        if (location?.state?.blogId) {
            setTypeCheck(location?.state?.type);
            setEditBlogId(location?.state?.blogId);
            getBlogDetails(location?.state?.blogId);
        }
    }, [location?.state?.blogId]);
    useEffect(() => {
        getBlogMedia();
        getBlogCategoryList();
    }, [])
    const getBlogDetails = async (blogId) => {
        setIsLoading(true);
        try {
            const response = await AxiosInstance.get(APILIST.GET_BLOG_DETAILS_BY_ID, { params: { id: blogId } });
            if (response && response?.data?.s) {
                const blogData = response?.data?.r;
                setBlogDetails(blogData);
                formik.setValues({
                    selectBlogCategory: blogData.category_id || 0,
                    selectTags: blogData?.blog_tags?.length > 0 ? blogData.blog_tags : [],
                    banner: blogData.cover_img ? `${Constant.BASE_URL}${blogData.cover_img}` : null,
                    title: blogData.title || "",
                    subTitle: blogData.subtitle || "",
                    element: blogData?.blog_elements?.length > 0 ? blogData.blog_elements[0]?.element : ""
                });
                setEditBlogElementId(blogData?.blog_elements[0]?.id);
                if (blogData.cover_img) {
                    setSelectedThumb(`${Constant.BASE_URL}${blogData.cover_img}`);
                }
            } else {
                setBlogDetails({});
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const getBlogCategoryList = async () => {
        try {
            const response = await AxiosInstance.get(APILIST.GET_BLOG_CATEGORY);
            if (response && response?.data?.s) {
                setBlogCategoryList(response?.data?.r);
            } else {
                setBlogCategoryList([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }
    const getBlogMedia = async () => {
        try {
            const response = await AxiosInstance.get(APILIST.GET_BLOG_DETAILS);
            if (response && response?.data?.s) {
                setMediaBlogDetails(response?.data?.r);
            } else {
                setMediaBlogDetails([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }
    const handleOnChangeThumb = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error("Invalid file type for banner!");
        } else {
            const file = acceptedFiles[0];
            if (file && ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(file.type)) {
                if (file.size <= 2 * 1024 * 1024) {
                    const videoUrl = URL.createObjectURL(file);
                    setSelectedThumbFile(file);
                    setSelectedThumb(videoUrl);
                    formik.setFieldValue('banner', file);
                } else {
                    toast.error(`File size exceeds the limit of 2MB`);
                }
            } else {
                toast.error("Upload Only(PNG.JPG,JPEG)");
            }
        }
    }, []);
    const handleOnChangeMedia = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error("Invalid file type for banner!");
        } else {
            const file = acceptedFiles[0];
            if (file && ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(file.type)) {
                if (file.size <= 2 * 1024 * 1024) {
                    const videoUrl = URL.createObjectURL(file);
                    setMediaFile(file);
                    setMediaFilePrev(videoUrl);
                } else {
                    toast.error(`File size exceeds the limit of 2MB`);
                }
            } else {
                toast.error("Upload Only(PNG.JPG,JPEG)");
            }
        }
    }, []);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            selectBlogCategory:0,
            selectTags:[],
            banner:null,
            title:"",
            subTitle:"",
            element:""
        },
        validationSchema: Yup.object().shape({
            selectBlogCategory: Yup.mixed()
                .test("is-not-zero", "Blog category is required!", value => value !== 0)
                .required("blog category is required!"),
            selectTags: Yup.array().of(
                Yup.object().shape({
                    tag: Yup.string().required("Tag is required")
                })
            ).min(1, "At least one tag is required"),
            banner: Yup.string().required("Banner is required"),
            title: Yup.string().required("Title is required"),
            subTitle: Yup.string().required("Sub title is required"),
            element: Yup.string().required("Element is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            if (isLoading) return;
            setIsLoading(true);
    
            const formData1 = new FormData();
            formData1.append("category_id", values.selectBlogCategory);
            formData1.append('title', values.title);
            formData1.append('subtitle', values.subTitle);
            if (selectedThumbFile) formData1.append('cover_img', selectedThumbFile);
    
            const handleTagsAndElements = async (blogId) => {
                const tags = values.selectTags.map((elem) => elem.tag).join(",");
                const formData2 = new FormData();
                if (typeCheck === "Edit") {
                    formData2.append('id', blogId);
                } else {
                    formData2.append('blog_id', blogId);
                } 
                formData2.append('tag', tags);
    
                const formData3 = new FormData();
                if (typeCheck === "Edit") {
                    formData3.append('id', editBlogElementId);
                } else {
                    formData3.append('blog_id', blogId);
                } 
                formData3.append('element', values.element);
                formData3.append('type', 1);
                formData3.append('position', 1);
    
                const apiElementCall = typeCheck === "Edit" 
                    ? APILIST.UPDATE_ELEMENT_BLOG 
                    : APILIST.ADD_ELEMENT_BLOG;
                    const apiTagsCall = typeCheck === "Edit" 
                    ? APILIST.UPDATE_TAGS_BLOG 
                    : APILIST.ADD_TAGS_BLOG;
                const [response1, response2] = await Promise.all([
                    AxiosInstance.post(apiTagsCall, formData2),
                    AxiosInstance.post(apiElementCall, formData3)
                ]);
    
                if (response1?.data?.s && response2?.data?.s) {
                    toast.success("Blog Added Successfully");
                    resetForm();
                    navigate('/admin/blog');
                }
            };
    
            try {
                let response;
                if (editBlogId && typeCheck === "Edit") {
                    formData1.append("id", editBlogId);
                    response = await AxiosInstance.post(APILIST.UPDATE_DELETE_BLOG, formData1);
                } else {
                    response = await AxiosInstance.post(APILIST.CREATE_BLOG, formData1);
                }
    
                if (response?.data?.s) {
                    const blogId = editBlogId || response?.data?.r?.id;
                    if (blogId) {
                        await handleTagsAndElements(blogId);
                    }
                }
            } catch (error) {
                console.log('error', error);
            } finally {
                setIsLoading(false);
            }
        },
    });
    

    const handleUploadMedia = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("media", mediaFile)
            const res = await AxiosInstance.post(APILIST.ADD_BLOG_ELEMENT, formData);
            if (res && res?.data?.s) {
                getBlogMedia();
                setMediaFile(null);
                setMediaFilePrev(null);
                setIsLoading(false);
            }
        } catch (error) {
            console.log('error', error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }
    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: " ",
            iframe: true,
            removeEmptyNodes: true,
            removeAttributes: ["style"],
        }),
        []
    );
    return (
        <Fragment>
            <Box sx={{
                width: '100%'
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    background: "#ffffff",
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                    padding: '10px 20px',
                    position: "fixed",
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    zIndex: 10
                }}>
                    <IconButton sx={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#101010',
                        borderRadius: '8px',
                        "&:hover": {
                            backgroundColor: '#101010',
                            color: '#fff',
                        }
                    }} onClick={() => navigate(-1)}>
                        <ArrowBackIosNewIcon sx={{ color: '#fff', fontSize: '28px' }} />
                    </IconButton>
                    <Typography sx={{ fontFamily: "Outfit", fontSize: { xs: "20px", md: "32px" }, fontWeight: "500", paddingBottom: '10px', color: '#000000' }}>
                        {typeCheck ? "Update" : "Add"} Blog
                    </Typography>
                    <CustomSubmitButton
                        btnTitle={typeCheck ? "Update" : "Submit"}
                        loading={isLoading}
                        type="submit"
                        onSubmit={formik.handleSubmit}
                        sx={{
                            width: '130px'
                        }}
                    />
                </Box>
                <Box sx={{
                    background: "rgba(239, 233, 255, 0.5)",
                    padding: '30px',
                    maxWidth: { xs: "95%" },
                    margin: "100px auto 50px auto",
                    width: "100%",
                    position: 'relative',
                    zIndex: 9,
                    borderRadius: '10px'
                }}>
                    <Box sx={{
                        maxWidth: { xs: "95%", lg: "80%", xl: "70%" },
                        margin: "auto auto",
                        width: "100%",
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        color: "black",
                                        fontWeight: "400",
                                        textAlign: "left",
                                        fontSize: { xs: "16px", sm: "18px" },
                                        mb: '8px'
                                    }}
                                >
                                    Banner
                                </Typography>
                                <CustomThumbUpload
                                    accept="image/*"
                                    onDrop={handleOnChangeThumb}
                                    selectedFile={selectedThumb}
                                    onRemove={(e) => {
                                        e.stopPropagation();
                                        setSelectedThumb(null);
                                        setSelectedThumbFile(null);
                                        formik.setFieldValue('banner', null)
                                    }}
                                    typeCheck={typeCheck}
                                    isLoading={isLoading}
                                    errors={formik.errors.banner}
                                />
                                {formik.touched.banner && formik.errors.banner ? (
                                    <Typography
                                        sx={{
                                            color: "red",
                                            fontWeight: "400",
                                            fontSize: { xs: "14px", sm: "14px" },
                                            marginTop: "5px",
                                        }}
                                    >
                                        {formik.errors.banner}
                                    </Typography>
                                ) : null}
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    label="Title"
                                    type="text"
                                    placeholder="Title"
                                    name="title"
                                    value={formik.values.title}
                                    onBlur={formik.handleBlur}
                                    onKeyUp={formik.handleBlur}
                                    errors={formik.touched.title && formik.errors.title}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value.trimStart() !== value) {
                                            e.target.value = value.trimStart();
                                        }
                                        formik.handleChange(e);
                                    }}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            borderRadius: "10px",
                                            // border: formik.errors.title ? "1px solid red" : "1px solid #BB9EFF",
                                            color: "black",
                                            fontSize: "18px",
                                            background: "white",
                                            fontFamily: "Outfit",
                                        },
                                    }}
                                    style={{ color: "black", fontSize: "18px" }}
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    label="SubTitle"
                                    type="text"
                                    placeholder="Subtitle"
                                    name="subTitle"
                                    value={formik.values.subTitle}
                                    onBlur={formik.handleBlur}
                                    onKeyUp={formik.handleBlur}
                                    errors={formik.touched.subTitle && formik.errors.subTitle}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value.trimStart() !== value) {
                                            e.target.value = value.trimStart();
                                        }
                                        formik.handleChange(e);
                                    }}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            borderRadius: "10px",
                                            // border: formik.errors.subTitle ? "1px solid red" : "1px solid #BB9EFF",
                                            color: "black",
                                            fontSize: "18px",
                                            background: "white",
                                            fontFamily: "Outfit",
                                        },
                                    }}
                                    style={{ color: "black", fontSize: "18px" }}
                                    isLoading={isLoading}
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomDropDown
                                    name='selectBlogCategory'
                                    Title={"Select Blog Category"}
                                    value={formik.values.selectBlogCategory}
                                    onChange={formik.handleChange}
                                    placeholder={'Select Blog Category'}
                                    menuList={blogCategoryList}
                                    NoData='No Blog Category Available'
                                    isLoading={isLoading}
                                    onBlur={formik.handleBlur}
                                    onKeyUp={formik.handleBlur}
                                    errors={formik.touched.selectBlogCategory && formik.errors.selectBlogCategory}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ChipMultiSelect
                                    onChange={(newValue) => formik.setFieldValue('selectTags', newValue)}
                                    selectedOptions={formik.values.selectTags}
                                    defaultOptions={[]}
                                    name={"selectTags"}
                                    isLoading={isLoading}
                                    errorss={formik.touched.selectTags && formik.errors.selectTags}
                                    onBlur={formik.handleBlur}
                                    onKeyUp={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {isLoading ? <Skeleton height={150} /> :
                                    (
                                        <>
                                            <CustomSubmitButton
                                                btnTitle={"Upload Image"}
                                                type="Button"
                                                sx={{
                                                    width: '160px',
                                                    marginBottom: "10px"
                                                }}
                                                onSubmit={() => setMediaFileModel(true)}
                                            />
                                            <JoditEditor
                                                ref={editor}
                                                value={formik.values.element}
                                                config={config}
                                                onBlur={(data) => formik.setFieldValue("element", data)}
                                                className={formik.errors.element ? 'jodit-editor-error' : 'jodit-editor'}
                                                onKeyUp={formik.handleBlur}
                                            />
                                            {formik.touched.element && formik.errors.element ? (
                                                <Typography
                                                    sx={{
                                                        color: "red",
                                                        fontWeight: "400",
                                                        fontSize: { xs: "14px", sm: "14px" },
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    {formik.errors.element}
                                                </Typography>
                                            ) : null}
                                        </>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </Box>
            <Drawer open={mediaFileModel}
                onClose={() => setMediaFileModel(false)}
                sx={{ zIndex: 1300 }}>
                <Box sx={{ width: 400 }} role="presentation" padding={'20px'} >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                    fontSize: { xs: "16px", sm: "18px" },
                                    mb: '8px'
                                }}
                            >
                                Media
                            </Typography>
                            <CustomThumbUpload
                                accept="image/*"
                                onDrop={handleOnChangeMedia}
                                selectedFile={mediaFilePrev}
                                onRemove={(e) => {
                                    e.stopPropagation();
                                    setSelectedThumb(null);
                                    setSelectedThumbFile(null);
                                }}
                                typeCheck={typeCheck}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <CustomSubmitButton
                                    btnTitle={"Upload"}
                                    type="Button"
                                    sx={{
                                        width: '160px',
                                        marginBottom: "10px"
                                    }}
                                    onSubmit={() => handleUploadMedia()}
                                    loading={isLoading}
                                />
                            </Box>
                        </Grid>
                        {mediaBlogDetails && mediaBlogDetails?.map((elem, index) => {
                            return (
                                <Grid item xs={12} md={6} key={index}>
                                    <Box sx={{
                                        width: '100%',
                                        position: 'relative'
                                    }}>
                                        <img src={`${Constant?.BASE_URL}${elem?.media}`} alt="blogImage" style={{
                                            width: '100%',
                                            borderRadius: '5px'
                                        }} />
                                        <Tooltip title='Copy Link' position="top">
                                            <IconButton sx={{
                                                position: 'absolute',
                                                right: '5px',
                                                top: '5px',
                                                backgroundColor: '#000',
                                                "&:hover": {
                                                    backgroundColor: '#000'
                                                }
                                            }} onClick={() => navigator.clipboard.writeText(`${Constant?.BASE_URL}${elem?.media}`)}>
                                                <ContentCopyIcon sx={{
                                                    color: '#fff'
                                                }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Drawer>
        </Fragment>

    )
}

export default AddBlog