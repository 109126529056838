import React, { Fragment, useEffect, useState } from "react";
import { Box, IconButton, Tooltip, Typography, Modal } from "@mui/material";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import AxiosInstance from "../../../Config/AxiosInstance";
import APILIST from "../../../Config/ApiList";
import ReplyIcon from "@mui/icons-material/Reply";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import Constant from "../../../Utils/Constant";
import CustomSubmitButton from "../../../Components/CustomSubmitButton/CustomSubmitButton";
import { toast } from "react-toastify";

const ContactUs = () => {
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = useState(false); // Modal open state
  const [selectedContact, setSelectedContact] = useState({}); // Selected contact details

  const handleOpen = (contact) => {
    setSelectedContact(contact);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedContact(null);
  };

  const columns = [
    { field: "rowid", headerName: "Sr.No.", width: 100 },
    {
      field: "full_name",
      headerName: "Name",
      flex: 1,
      minWidth: 300,
      renderCell: (params) =>
        params?.row?.full_name ? params?.row?.full_name : "--",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (params?.row?.email ? params?.row?.email : "--"),
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Tooltip title={params?.row?.subject || "--"}>
          <span>{params?.row?.subject || "--"}</span>
        </Tooltip>
      ),
    },
    {
      field: "message",
      headerName: "Description",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Tooltip title={params?.row?.message || "--"}>
          <span>{params?.row?.message || "--"}</span>
        </Tooltip>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <IconButton
            onClick={() => handleResolveSubmit(params?.row)}
            disabled={params?.row?.is_resolve}
            sx={{boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
          >
            <ReplyIcon
              sx={{
                color: params?.row?.is_resolve ? "grey" : "#D0BCFF",
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    getContactUsList();
  }, [page]);

  const getContactUsList = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(APILIST.GET_CONTACT_US, {
        params: {
          count: page * pageSize,
          offset: pageSize,
        },
      });
      if (response && response?.data?.s) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setUserList(Rowdata);
        setCount(response?.data?.c || 0);
      } else {
        setUserList([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleResolveSubmit = async (data) => {
    try {
      const res = await AxiosInstance.post(APILIST.RESOLVE_CONTACTUS, {
        id: data?.id,
      });
      if (res && res?.data?.s) {
        toast.success(res?.data?.m);
        getContactUsList();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "15px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "30px" },
              fontWeight: "500",
              paddingBottom: "10px",
            }}
          >
            Contact Us
          </Typography>
        </Box>
        <Box sx={{ padding: "24px 0px 24px 0px" }}>
          <TableContainer
            rows={userList}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            totalCount={count}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default ContactUs;
