import { Box, Checkbox, Dialog, FormControlLabel, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import { useFormik } from 'formik';
const SubAdminRightModel = (props) => {
    const { open, onClose, handleOnClose, subAdminRightAccessId } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [subRightsList, setSubRightList] = useState([]);
    const [checkedRights, setCheckedRights] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    useEffect(() => {
        if (open) {
            getSubRightModule(subAdminRightAccessId);
        }

    }, [open])
    const getSubRightModule = async (subAdminRightAccessId) => {
        setIsLoading(true);
        try {
            const response = await AxiosInstance.get(APILIST.GET_SUB_RIGHT, {
                params: {
                    user_id: subAdminRightAccessId
                }
            });
            if (response && response.data.s) {
                setSubRightList(response.data.r);
                const initialCheckedRights = {};
                const selectedModuleIds = [];
                const selectedAccessRightIds = [];

                response.data.r.forEach((right) => {
                    initialCheckedRights[`right-${right.id}`] = right.status === 1 && right.admin_user_id !== null; // Check if admin_user_id is not null
                    if (right.status === 1) {
                        selectedModuleIds.push(`${right.id}`);
                    }
                    right.access.forEach((accessRight) => {
                        initialCheckedRights[`access-${accessRight.id}`] = accessRight.admin_user_id !== null; // Keep this unchanged
                        if (accessRight.admin_user_id !== null) {
                            selectedAccessRightIds.push(`${accessRight.id}`);
                        }
                    });
                });
                setCheckedRights(initialCheckedRights);
                setSelectAll(Object.values(initialCheckedRights).every(value => value === true));
                formik.setValues({
                    module_ids: selectedModuleIds.join(','),
                    access_right_ids: selectedAccessRightIds.join(',')
                });
            } else {
                setSubRightList([]);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };
    const formik = useFormik({
        initialValues: {
            sub_access_id: subAdminRightAccessId,
            module_ids: ""
        },
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const formData = new FormData();
                formData.append('user_id', subAdminRightAccessId);
                formData.append('module_ids', values.module_ids);
                formData.append('access_right_ids', 0);
                const response = await AxiosInstance.post(APILIST.ADD_UPDATE_SUB_RIGHT, formData);
                if (response && response?.data?.s) {
                    handleOnClose();
                } else {
                    alert('Failed to Update Sub Admin Right Access');
                }
            } catch (error) {
                console.log('error', error);
                alert('Failed to Update Sub Admin Right Access');
            } finally {
                setIsLoading(false);
            }
        }
    })
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        const newCheckedRights = {};
        const selectedModuleIds = [];
        const selectedAccessRightIds = [];

        subRightsList.forEach((right) => {
            newCheckedRights[`right-${right.id}`] = isChecked;
            if (isChecked) {
                selectedModuleIds.push(`${right.id}`);
            }
            right.access.forEach((accessRight) => {
                newCheckedRights[`access-${accessRight.id}`] = isChecked;
                if (isChecked) {
                    selectedAccessRightIds.push(`${accessRight.id}`);
                }
            });
        });

        setCheckedRights(newCheckedRights);
        formik.setFieldValue('module_ids', selectedModuleIds.join(','));
        formik.setFieldValue('access_right_ids', selectedAccessRightIds.join(','));
    };

    const handleCheckboxChange = (id, event) => {
        const isChecked = event.target.checked;
        const [type, itemId] = id.split('-');
    
        // Update the checkedRights state
        setCheckedRights(prev => {
            const newCheckedRights = { ...prev, [id]: isChecked };
    
            // Handle module (right) checkbox change
            if (type === 'right') {
                let moduleIds = formik.values.module_ids.split(',');
                if (isChecked) {
                    moduleIds.push(itemId);
                } else {
                    moduleIds = moduleIds.filter(item => item !== itemId);
                    const relatedRight = subRightsList.find(right => right.id === Number(itemId));
                    if (relatedRight) {
                        relatedRight.access.forEach(accessRight => {
                            newCheckedRights[`access-${accessRight.id}`] = false; // Uncheck related access rights
                            formik.setFieldValue('access_right_ids', formik.values.access_right_ids.split(',').filter(item => item !== `${accessRight.id}`).join(','));
                        });
                    }
                }
                formik.setFieldValue('module_ids', moduleIds.filter(Boolean).join(','));
            } 
            // Handle access right checkbox change
            else {
                let accessRightIds = formik.values.access_right_ids.split(',');
                if (isChecked) {
                    accessRightIds.push(itemId);
                    const relatedRight = subRightsList.find(right => right.access.some(accessRight => accessRight.id === Number(itemId)));
                    if (relatedRight) {
                        let moduleIds = formik.values.module_ids.split(',');
                        if (!moduleIds.includes(`${relatedRight.id}`)) {
                            moduleIds.push(`${relatedRight.id}`);
                        }
                        formik.setFieldValue('module_ids', moduleIds.filter(Boolean).join(','));
                        newCheckedRights[`right-${relatedRight.id}`] = true; // Check the related right checkbox
                    }
                } else {
                    accessRightIds = accessRightIds.filter(item => item !== itemId);
                }
                formik.setFieldValue('access_right_ids', accessRightIds.filter(Boolean).join(','));
            }
    
            // Recalculate selectAll status based on the updated newCheckedRights
            const allChecked = subRightsList.every((right) =>
                newCheckedRights[`right-${right.id}`] && right.access.every((accessRight) => newCheckedRights[`access-${accessRight.id}`])
            );
            setSelectAll(allChecked);
    
            return newCheckedRights; // Return the updated state
        });
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    padding: '20px 30px',
                    height: "600px",
                    overflow: "scroll",
                    "::-webkit-scrollbar": { width: "0px", height: '0px' }
                },
                "& .MuiDialogContent-root": {
                    overflowY: "initial",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }
            }}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', color: '#101010', fontFamily: "Outfit", marginBottom: '10px' }}>Right Modules</Typography>
                        <FormControlLabel
                            label="Select All"
                            control={<Checkbox checked={selectAll}
                                onChange={handleSelectAllChange} sx={{
                                    color: '#b191ff',
                                    '&.Mui-checked': {
                                        color: '#b191ff',
                                    },
                                }} />}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <IconButton sx={{
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#101010',
                                borderRadius: '8px',
                                "&:hover": {
                                    backgroundColor: '#101010',
                                    color: '#fff',
                                }
                            }} onClick={handleOnClose}>
                                <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    padding: '32px 0px 0px 0px',
                }}>
                    <Grid spacing={1} container>
                        {subRightsList && subRightsList?.length > 0 && subRightsList?.map((elem, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={`right-${elem.id}`}
                                                checked={!!checkedRights[`right-${elem.id}`]}
                                                onChange={(event) => handleCheckboxChange(`right-${elem.id}`, event)}
                                                sx={{
                                                    color: '#b191ff',
                                                    '&.Mui-checked': {
                                                        color: '#b191ff',
                                                    },
                                                }}
                                            />
                                        }
                                        label={elem?.name}
                                    />


                                </Grid>
                            )
                        })}
                        <Grid item xs={12}>
                            <CustomSubmitButton
                                btnTitle={"Submit"}
                                loading={isLoading}
                                type="submit"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Dialog>
    );
};

export default SubAdminRightModel;