import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';
import Skeleton from 'react-loading-skeleton';

const CUstomTimePicker = ({ value, onChange, error, inputFormat, sx = {}, isLoading }) => {
  const validValue = dayjs.isDayjs(value) ? value : null;

  return (
    <React.Fragment>
      {isLoading ? (
        <Skeleton height={60} />
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={validValue}
            onChange={onChange}
            inputFormat={inputFormat}
            ampm={false}
            showTodayButton
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                helperText={error}
                fullWidth
              />
            )}
            sx={{
              width: '100%',
              ...sx,
            }}
          />
        </LocalizationProvider>
      )}
    </React.Fragment>
  );
};

export default CUstomTimePicker;
