import { Box, Checkbox, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CustomSearch from '../../../Components/CustomSearch/CustomSearch';
import TableContainer from '../../../Components/TableContainer/TableContainer';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';

const AssignWorkoutModel = (props) => {
    const { count, open, onClose, handleOnClose, pageSize, page, checkedWorkOut, setCheckedWorkOut, searchWorkOut, setSearchWorkOut, isWorkoutDetailsList, handlePageChange, handleOnClickWorkOutIsAdded } = props;
    const columns = [
        {
            field: "rowid", headerName: "Sr.No.", minWidth: 50,
            renderCell: (params) => {
                return (
                    <Checkbox
                        checked={!!checkedWorkOut[`${params?.row?.id}`]}
                        onChange={(event) => handleCheckboxChange(`${params?.row?.id}`, event)}
                    />
                )
            }
        },
        {
            field: "title",
            headerName: "Title",
            minWidth: 100,
            flex: 1,
            renderCell: (params) => params?.row?.title || "--"
        },
        {
            field: "day",
            headerName: "Days",
            minWidth: 20,
            renderCell: (params) => params?.row?.day || "--"
        },
        {
            field: "week",
            headerName: "Weeks",
            minWidth: 100,
            renderCell: (params) => `${params?.row?.week} Weeks` || "--"
        },
    ];
    const handleCheckboxChange = (id, event) => {
        const updatedCheckedWorkOut = {
            ...checkedWorkOut,
            [id]: event.target.checked
        };
        setCheckedWorkOut(updatedCheckedWorkOut);
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="md"
                fullWidth
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "20px",
                        padding: '20px 30px',
                    },
                    "& .MuiBackdrop-root": {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    }
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', color: '#101010', fontFamily: "Outfit" }}>
                            Select WorkOut Details
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <IconButton
                                sx={{
                                    width: '30px',
                                    height: '30px',
                                    backgroundColor: '#101010',
                                    borderRadius: '8px',
                                }}
                                onClick={handleOnClose}
                            >
                                <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ paddingTop: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <CustomSearch
                                placeholder="Search Workout (Title, Week, Day)"
                                value={searchWorkOut}
                                onChange={(event) => setSearchWorkOut(event.target.value)}
                                onClear={() => setSearchWorkOut("")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <CustomSubmitButton
                                    btnTitle={"Submit"}
                                    type="button"
                                    sx={{ width: '130px' }}
                                    onSubmit={() => handleOnClickWorkOutIsAdded(checkedWorkOut)}
                                />
                            </Box>
                        </Grid>
                        <Box sx={{
                            paddingTop: '20px'
                        }}>
                            <TableContainer
                                rows={isWorkoutDetailsList}
                                columns={columns}
                                pageSize={pageSize}
                                page={page}
                                totalCount={count}
                                handlePageChange={handlePageChange}
                            />
                        </Box>
                    </Grid>
                </Box>
            </Dialog>
        </Fragment>
    );
};

export default AssignWorkoutModel;
