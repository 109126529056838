import React, { Fragment, useState } from 'react';
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import AddWorkoutModel from './AddWorkoutDetailsModel';
import CustomAction from '../../../Components/CustomAction/CustomAction';
import Swal from 'sweetalert2';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import { toast } from 'react-toastify';

const PlanWorkOutList = (props) => {
    const {
        selectedWeekID,
        setIsOpenAddWorkOutModel,
        isOpenAddWorkOutModel,
        selectedWeekData,
        isSelectedDays,
        isSelectedDay,
        handleSubmitWorkout,
        onDeleteUpdate,
        handleDeletePlandetailsDayAllPosition,
        value
    } = props;
    const [isSelectWorkOutCardPostion, setIsSelectWorkOutPostion] = useState(null);
    const [editPlanWorkOutDetails, setEditPlanWorkoutDetails] = useState({});
    const shouldShowAddWorkout = (day) => {
        if (!selectedWeekData || !selectedWeekData.workout_details) return true;
        const workoutsForDay = selectedWeekData.workout_details.filter(
            workout => workout.day == day
        );
        return workoutsForDay.length === 0;
    };
    const filteredPlanDays = Array.from(new Set(isSelectedDays || [])).sort((a, b) => a - b);
    const handleAddWorkOut = (position) => {
        const day = Object.keys(position)[0];
        setIsOpenAddWorkOutModel(true);
        setIsSelectWorkOutPostion(day)
    };
    const handleDeletePlanDetails = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this workout",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#46adf2",
            cancelButtonColor: "#5b5d5d",
            confirmButtonText: "Yes, delete it",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const params = {
                    id: id,
                    status: 0,
                };
                try {
                    const response = await AxiosInstance.post(
                        APILIST.UPDATE_DELETE_WORKOUT_DETAILS,
                        params
                    );
                    if (response?.data?.s === 1) {
                        toast.success("Workout Deleted Sucessfully !.");
                        onDeleteUpdate();
                    }
                } catch (error) {
                    toast.error("Failed to delete plan details . Please try again.");
                }
            }
        });
    };
    const handleEditPlanDetails = (data) => {
        setIsOpenAddWorkOutModel(true);
        if (data?.id) {
            setEditPlanWorkoutDetails(data);
        }
    }
    return (
        <Fragment>
            {filteredPlanDays && (
                <Box sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: '15px', padding: '20px', height:'500px',overflowY:'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ color: 'black', fontWeight: '400', textAlign: 'left', fontSize: { xs: '16px', sm: '18px' }, mb: '8px' }}>
                            Plan For {filteredPlanDays.length} {filteredPlanDays.length > 1 ? 'days' : 'day'}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {selectedWeekData?.workout_details?.filter(workout => workout?.day == isSelectedDay)?.length > 0 && (
                                <Button sx={{
                                    width: '70px',
                                    background: "rgba(204, 51, 0, 1)",
                                    color: '#fff',
                                    fontSize: '10px',
                                    fontWeight: '400',
                                    borderRadius: '8px',
                                    mb: "10px",
                                    "&:hover": {
                                        background: "rgba(204, 51, 0, 1)",
                                        color: '#fff',
                                    }
                                }} onClick={() => handleDeletePlandetailsDayAllPosition(selectedWeekData?.workout_details)}>
                                    Delete
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <Grid container spacing={3}>
                        {filteredPlanDays.map((elem, index) => {
                            const day = Object.keys(elem)[0];
                            const workoutsForDay = selectedWeekData?.workout_details ? selectedWeekData.workout_details.filter(
                                workout => workout?.day == day
                            ) : [];                            
                            const showAddWorkout = shouldShowAddWorkout(day, isSelectedDay);
                            return (
                                <Grid item xs={12} key={index}>

                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: '10px'
                                    }}>
                                        <Typography sx={{ color: '#B190FF', fontWeight: '400', textAlign: 'left', fontSize: { xs: '16px', sm: '16px' }, mb: '10px' }}>
                                            {`Workout day ${day}`}
                                        </Typography>
                                        {workoutsForDay?.length > 0 ? <Button sx={{
                                            color: '#fff',
                                            background: "#B190FF",
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            borderRadius: '8px',
                                            "&:hover": {
                                                color: '#fff',
                                                background: "#B190FF",
                                            }
                                        }} onClick={() => handleAddWorkOut({ [day]: [] })}>Add More</Button> : null}
                                    </Box>
                                    {workoutsForDay?.length > 0 ? (
                                        <>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="workout table">
                                                    <TableBody>
                                                        {workoutsForDay?.map((workout, workoutIndex) => (
                                                            <TableRow key={workoutIndex}>
                                                                <TableCell component="th" scope="row">{workoutIndex + 1}</TableCell>
                                                                <TableCell component="th" scope="row">{workout?.name}</TableCell>
                                                                <TableCell align="right">{workout?.value}</TableCell>
                                                                <TableCell align="right">{workout?.type === 1 ? "Min" : "Mile"}</TableCell>
                                                                <TableCell align="right">
                                                                    <CustomAction
                                                                        showDelete={true}
                                                                        showEdit={true}
                                                                        isDelete={() => handleDeletePlanDetails(workout?.id)}
                                                                        isEdit={() => handleEditPlanDetails(workout)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>

                                    ) : showAddWorkout && (
                                        <Box
                                            sx={{
                                                borderRadius: '20px',
                                                border: '1px solid #D0BCFF',
                                                height: '100px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                mt: workoutsForDay.length > 0 ? 2 : 0
                                            }}
                                            onClick={() => handleAddWorkOut({ [day]: [] })}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#B190FF',
                                                    fontWeight: '400',
                                                    fontSize: { xs: '16px', sm: '18px' },
                                                    '&:hover': { textDecoration: 'underline' },
                                                }}
                                            >
                                                Add Workout
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            )}
            <AddWorkoutModel
                open={isOpenAddWorkOutModel}
                onClose={() => { setIsOpenAddWorkOutModel(false); }}
                handleOnClose={() => { setIsOpenAddWorkOutModel(false); }}
                selectedWeekID={selectedWeekID}
                isSelectWorkOutCardPostion={isSelectWorkOutCardPostion}
                editPlanWorkOutDetails={editPlanWorkOutDetails}
                handleSubmitWorkout={handleSubmitWorkout}
            />
        </Fragment>
    );
};

export default PlanWorkOutList;
