import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import { toast } from 'react-toastify';
const AddWorkOutTitleModel = (props) => {
    const { open, onClose, handleOnSubmit, handleOnClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: "",
            total_weeks: "",
            total_days: "",
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Title is required"),
            total_weeks: Yup.string().required("Total Weeks is required"),
            total_days: Yup.string().required("Total Days is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            if (isLoading) {
                return;
            } else {
                try {
                    const formData = new FormData();
                    formData.append("title", values.title);
                    formData.append("week", values.total_weeks);
                    formData.append("day", values.total_days);
                    const result = await AxiosInstance.post(APILIST.ADD_WORKOUT, formData);
                    if (result && result?.data?.s) {
                        const data = result.data?.r
                        toast.success('Workout added successfully');
                        resetForm();
                        handleOnSubmit(data);
                    }
                } catch (error) {
                    console.log('error', error);
                    toast.error(error);
                }
            }
        },
    });
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    padding: '20px 30px',
                    height: "600px",
                    overflow: "scroll",
                    "::-webkit-scrollbar": { width: "0px", height: '0px' }
                },
                "& .MuiDialogContent-root": {
                    overflowY: "initial",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }
            }}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', color: '#101010', fontFamily: "Outfit" }}>Select Days</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <IconButton sx={{
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#101010',
                                borderRadius: '8px',
                                "&:hover": {
                                    backgroundColor: '#101010',
                                    color: '#fff',
                                }
                            }} onClick={handleOnClose}>
                                <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    padding: '20px 0px 0px 0px',
                }}>
                    <Grid spacing={2} container>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Title"
                                type="text"
                                placeholder="Title"
                                name="title"
                                value={formik.values.title}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.title && formik.errors.title}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.title ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Total Weeks"
                                type="number"
                                min="0"
                                max={1}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                }
                                placeholder="Enter Total Weeks"
                                name="total_weeks"
                                value={formik.values.total_weeks}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.total_weeks && formik.errors.total_weeks}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.total_weeks ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Total Days"
                                type="number"
                                min="0"
                                max={1}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                }
                                placeholder="Enter Total Days"
                                name="total_days"
                                value={formik.values.total_days}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.total_days && formik.errors.total_days}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.total_days ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CustomSubmitButton
                                btnTitle={'Submit'}
                                loading={isLoading}
                                type="submit"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Dialog>
    );
};

export default AddWorkOutTitleModel;