import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DummyUser from "../../../Assets/Images/Common/avtar.png";
import AxiosInstance from "../../../Config/AxiosInstance";
import APILIST from "../../../Config/ApiList";
import moment from "moment/moment";
import Constant from "../../../Utils/Constant";
import Fire from "../../../Assets/Images/Common/strikeFire.png";
import UserDetailsGraph from "./UserDetailsGraph";
const UserDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [userPlanDetails, setUserPlanDetails] = useState({});
  const getUserDetails = async () => {
    try {
      const res = await AxiosInstance.get(APILIST.GET_BY_ID, {
        params: { user_id: location?.state?.id },
      });
      if (res && res?.data?.s === 1) {
        setUserDetails(res?.data?.r);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserPlan = async () => {
    try {
      const res = await AxiosInstance.get(APILIST.GET_MY_PLAN, {
        params: { user_id: location?.state?.id },
      });
      if (res && res?.data?.s === 1) {
        setUserPlanDetails(res?.data?.r);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserDetails();
    getUserPlan();
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          paddingX: "30px",
          paddingY: "15px",
        }}
      >
        {/* Header  */}
        <Box sx={{ display: "flex", gap: "20px", alignItems: "center", mb: 2 }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosNewIcon
              sx={{ fontSize: { xs: "22px", xl: "32px" }, color: "black" }}
            />
          </IconButton>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: { xs: "20px", xl: "32px" },
              fontWeight: "500",
              //   paddingBottom: "10px",
              color: "#000000",
            }}
          >
            User Details
          </Typography>
        </Box>
        {/* User Basic Info */}
        <Box
          sx={{
            background: "#EFE9FF80",
            width: "100%",
            display: "flex",
            borderRadius: "15px",
          }}
        >
          <Box sx={{ width: "100%", display: "flex" }}>
            <Avatar sx={{ width: "80px", height: "80px", m: 2 }}>
              <img
                src={`${Constant.BASE_URL}${userDetails?.profile_img}`}
                alt=""
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Avatar>
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                sx={{ fontSize: { xs: "16px", xl: "20px" }, fontWeight: "500" }}
              >
                {`${userDetails?.user_name} (${
                  userDetails?.gender === 1 ? "Male" : "Female"
                })`}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "14px", xl: "18px" }, fontWeight: "500" }}
              >
                {userDetails?.full_name}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "14px", xl: "18px" }, fontWeight: "400" }}
              >
                {userDetails?.email}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "14px", xl: "18px" }, fontWeight: "400" }}
              >
                DOB : {moment(userDetails?.dob).format("DD / MM / YYYY")}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "14px", xl: "18px" }, fontWeight: "400" }}
              >
                Location : {userDetails?.location}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "end", p: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Button
                sx={{
                  width: "180px",
                  textTransform: "none",
                  fontSize: { xs: "16px", xl: "20px" },
                  background: "#BEBEBE33",
                  color: "black",
                  "&:hover": {
                    background: "#BEBEBE33",
                    color: "black",
                  },
                }}
              >
                Restrict
              </Button>
              <Button
                sx={{
                  width: "180px",
                  textTransform: "none",
                  fontSize: { xs: "16px", xl: "20px" },
                  background: "#E5000033",
                  color: "#E50000",
                  "&:hover": {
                    background: "#E5000033",
                    color: "#E50000",
                  },
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
        {/* Other Details */}
        <Box
          sx={{
            width: "100%",

            p: 2,
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: "15px",
          }}
        >
          <Box sx={{ width: { xs: "100%", lg: "40%" } }}>
            <Box sx={{ display: "flex", width: "100%", gap: "15px" }}>
              <Box sx={{ width: "60%" }}>
                <Typography
                  sx={{ mb: 2, fontSize: { xs: "16px", xl: "20px" } }}
                >
                  Current Plan
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    background: "#EFE9FF",
                    borderRadius: "15px",
                    p: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Race Date :{" "}
                    {userPlanDetails?.date
                      ? moment(userPlanDetails?.date).format("DD / MM / YYYY")
                      : "--"}
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Goal : {userPlanDetails?.category_names}
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Level :{" "}
                    {userPlanDetails?.level === "1"
                      ? "Beginner"
                      : userPlanDetails?.level === "2"
                      ? "Intermediate"
                      : userPlanDetails?.level === "3" && "Advance"}
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Plan : {userPlanDetails?.week}-week plan
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Days : {userPlanDetails?.week_day?.split(", ")?.length} runs
                    per week
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Completed: 8 Week
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: "40%" }}>
                <Typography
                  sx={{ mb: 2, fontSize: { xs: "16px", xl: "20px" } }}
                >
                  Strike
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    background: "#EFE9FF",
                    borderRadius: "15px",
                    p: 1.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    boxSizing: "border-box",
                  }}
                >
                  <img
                    src={Fire}
                    alt=""
                    style={{ width: "100px", height: "100px" }}
                  />
                  <Typography sx={{ fontSize: { xs: "30px", xl: "52px" } }}>
                    {userDetails?.streak_count}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
                <UserDetailsGraph/>
            </Box>
          </Box>
          <Box sx={{ width: { xs: "100%", lg: "60%" } }}></Box>
        </Box>
      </Box>
    </>
  );
};

export default UserDetails;
