import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import VIEW_ICON from "../../Assets/Images/Common/view.svg";
import DELETE_ICON from "../../Assets/Images/Common/delete.svg";
import EDIT_ICON from "../../Assets/Images/Common/edit.svg";
import BlockIcon from '@mui/icons-material/Block';
import MessageIcon from '@mui/icons-material/Message';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LockResetIcon from '@mui/icons-material/LockReset';
const CustomAction = (props) => {
  const { isDelete,
    isEdit,
    isView,
    showDelete,
    showEdit,
    showView,
    showChat,
    showAccount,
    showPassword,
    isPassword,
    isShowAccount,
    showBlock, showUnBlock, handleBlock, handleUnBlock, userId, currentStatus, handleOpenChat } =
    props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gridGap: "10px",
      }}
    >
      {showView && (
        <Tooltip title='View'>
          <IconButton
            sx={{
              backgroundcColor: "#ffffff",
              width: "30px",
              height: "30px",
            }}
            onClick={isView}
          >
            <img
              src={VIEW_ICON}
              alt="viewIcon"
              style={{
                width: "30px",
              }}
            />
          </IconButton>
        </Tooltip>

      )}
      {showEdit && (
        <Tooltip title='Edit'>
          <IconButton
            sx={{
              backgroundcColor: "#ffffff",
              width: "30px",
              height: "30px",
            }}
            onClick={isEdit}
          >
            <img
              src={EDIT_ICON}
              alt="editIcon"
              style={{
                width: "30px",
              }}
            />
          </IconButton>
        </Tooltip>
      )}
      {showDelete && (
        <Tooltip title='Delete'>
          <IconButton
            sx={{
              backgroundcColor: "#ffffff",
              width: "30px",
              height: "30px",
            }}
            onClick={isDelete}
          >
            <img
              src={DELETE_ICON}
              alt="deleteIcon"
              style={{
                width: "30px",
              }}
            />
          </IconButton>
        </Tooltip>
      )}
      {showBlock && (
        <Tooltip title='Block'>
          <IconButton
            sx={{ backgroundColor: "#f44336", width: "30px", height: "30px", borderRadius: "4px", "&:hover": { backgroundColor: "#d32f2f" } }}
            onClick={() => handleBlock(userId, currentStatus)}
          >
            <BlockIcon sx={{color:'#fff', fontSize:'20px'}}/>
          </IconButton>
        </Tooltip>
      )}
      {showUnBlock && (
        <Tooltip title='UnBlock'>
          <IconButton
            sx={{ backgroundColor: "#4caf50", width: "30px", height: "30px", borderRadius: "4px", "&:hover": { backgroundColor: "#388e3c" } }}
            onClick={() => handleUnBlock(userId, currentStatus)}
          >
            <BlockIcon sx={{ fontSize: '20px', color: '#fff' }} />
          </IconButton>
        </Tooltip>
      )}
      {showChat && (
         <Tooltip title='Chat'>
         <IconButton
           sx={{ backgroundColor: "#4caf50", width: "30px", height: "30px", borderRadius: "4px", "&:hover": { backgroundColor: "#388e3c" } }}
           onClick={() => handleOpenChat(userId)}
         >
           <MessageIcon sx={{ fontSize: '20px', color: '#fff' }} />
         </IconButton>
       </Tooltip>
      )}
      {showAccount && (
         <Tooltip title='Manage Account'>
         <IconButton
           sx={{ backgroundColor: "#4caf50", width: "30px", height: "30px", borderRadius: "4px", "&:hover": { backgroundColor: "#388e3c" } }}
         onClick={isShowAccount}>
           <ManageAccountsIcon sx={{ fontSize: '25px', color: '#fff' }} />
         </IconButton>
       </Tooltip>
      )}
      {showPassword && (
         <Tooltip title='Reset Password'>
         <IconButton
           sx={{ backgroundColor: "#4caf50", width: "30px", height: "30px", borderRadius: "4px", "&:hover": { backgroundColor: "#388e3c" } }}
           onClick={isPassword}>
           <LockResetIcon sx={{ fontSize: '25px', color: '#fff' }} />
         </IconButton>
       </Tooltip>
      )}
    </Box>
  );
};

export default CustomAction;
