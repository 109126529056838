import { Box } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import * as React from "react";

export default function UserDetailsGraph() {
  return (
    <Box
      sx={{
        mt: 2.5,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        borderRadius:"15px"
      }}
    >
      <LineChart
        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
        series={[
          {
            data: [2, 5.5, 2, 8.5, 1.5, 5],
          },
        ]}
        sx={{ width: { xs: "500px", xl: "100%" } }}
        height={300}
      />
    </Box>
  );
}
