import { useEffect, useContext } from "react";
import React from "react";
import AxiosInstance from "../../Config/AxiosInstance";
import APILIST from "../../Config/ApiList";
import { StateStore } from "./StateContext";

function Middleware({ children }) {
    const { isAdmin, setIsAdmin } = useContext(StateStore);
    let userInfo = null;
    
    
    try {
        const userData = localStorage.getItem("TRANSFORMATIONAL_RUNNER");
        if (userData) {
            userInfo = JSON.parse(userData);
        }
    } catch (error) {
        userInfo = null;
    }
    useEffect(() => {
        const handleVerify = async () => {
            if (userInfo?.role === 2) {
                return;
            }
            if (userInfo?.id) {
                try {
                    const res = await AxiosInstance.get(APILIST.GET_SUB_RIGHT, {
                        params: { user_id: userInfo.id },
                    });
                    if (res && res?.data?.s) {
                        setIsAdmin(res?.data?.r);
                    }
                } catch (error) {
                    console.error("Error fetching user details:", error);
                }
            }
        };
    
        handleVerify();
    }, [children]);
    

    return <>{children}</>;
}

export default Middleware;
