import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import CustomDropDown from '../../../Components/CustomDropDown/CustomDropDown';
import CustomCheckBox from '../../../Components/CustomCheckBox/CustomCheckBox';
import { toast } from 'react-toastify';
import APILIST from '../../../Config/ApiList';
import AxiosInstance from '../../../Config/AxiosInstance';
const AddWorkoutModel = (props) => {
    const { open, onClose, handleSubmitWorkout, handleOnClose, selectedWeekID, isSelectWorkOutCardPostion, editPlanWorkOutDetails } = props;
    console.log('selectedWeekID', selectedWeekID);
    
    const [isLoading, setIsLoading] = useState(false);
    const typeDaily = [
        { id: "1", name: "Warm up", value: "Warm up" },
        { id: "2", name: "Cool down", value: "Cool down" },
        { id: "3", name: "Main set", value: "Main set" },
    ];
    const workOutTypeList = [
        { id: "1", name: "Min", value: "Min" },
        { id: "2", name: "Mile", value: "Mile" },
    ]
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            selectTypeDaily: 0,
            value: '',
            type: 0,
            sets: "",
            comment: '',
            checkRecovery: false,
            coachNotes: '',
            rValue: '',
            rType: 0,
            rComment: '',
        },
        validationSchema: Yup.object().shape({
            selectTypeDaily: Yup.mixed()
                .test(
                    "is-not-zero",
                    "Select type is required!",
                    (value) => value !== 0
                )
                .required("Select type is required!"),
            value: Yup.string().required("Value is required!"),
            sets: Yup.string().required("Sets is required!"),
            type: Yup.mixed()
                .test(
                    "is-not-zero",
                    "Type is required!",
                    (value) => value !== 0
                )
                .required("Type is required!"),
            rValue: Yup.string().test(
                "required-if-recovery",
                "Recovery value is required!",
                function (value) {
                    const { checkRecovery } = this.parent;
                    return !(checkRecovery && !value);
                }
            ),
            rType: Yup.string()
                .test(
                    "required-if-recovery",
                    "Recovery type is required!",
                    function (value) {
                        const { checkRecovery } = this.parent;
                        return !(checkRecovery && (!value || value === "0"));
                    }
                ),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            if (isLoading) {
                return;
            } else {
                try {
                    const formData = new FormData();
                    formData.append('workout_week_id', selectedWeekID);
                    if (editPlanWorkOutDetails?.id) {
                        formData.append('day', editPlanWorkOutDetails?.day);
                    } else {
                        formData.append('day', isSelectWorkOutCardPostion);
                    }
                    const selectedTypeDaily = typeDaily.find((elem) => elem?.id === values?.selectTypeDaily);
                    formData.append('name', selectedTypeDaily?.name || '');
                    formData.append('value', values.value);
                    formData.append('sets', values.sets);
                    formData.append('type', values.type);
                    if (values.comment) {
                        formData.append('comment', values.comment);
                    }
                    formData.append('is_recovery', values.checkRecovery ? 1 : 0);
                    if (values.checkRecovery) {
                        formData.append('r_value', values.rValue || '');
                        formData.append('r_type', values.rType || '');
                        if (values.rComment) {
                            formData.append('r_comment', values.rComment || '');
                        }
                    }
                    if (editPlanWorkOutDetails?.id) {
                        formData.append('id', editPlanWorkOutDetails?.id);
                    }
                    if(values.coachNotes){
                        formData.append('coach_notes', values.coachNotes);
                    }
                    
                    const apiEndPoint = editPlanWorkOutDetails?.id ? APILIST.UPDATE_DELETE_WORKOUT_DETAILS : APILIST.ADD_WORKOUT_DETAILS;
                    const response = await AxiosInstance.post(apiEndPoint, formData);
                    if (response && response?.data?.s) {
                        toast.success(editPlanWorkOutDetails?.id ? "Workout Details Updated Successfully!" : 'Workout Details Added Successfully!');
                        resetForm();
                        
                        handleSubmitWorkout()
                    } else {
                        toast.error(response?.data?.m);
                    }
                } catch (error) {
                    console.error('Error adding workout details:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    });
    useEffect(() => {
        if (editPlanWorkOutDetails?.id) {
            const selectedTypeDaily = typeDaily.find((elem) => elem?.name === editPlanWorkOutDetails.name);
            formik.setValues({
                selectTypeDaily: selectedTypeDaily?.id || 0,
                value: editPlanWorkOutDetails.value || '',
                type: editPlanWorkOutDetails.type || 0,
                sets: editPlanWorkOutDetails.sets || "",
                comment: editPlanWorkOutDetails.comment || '',
                checkRecovery: editPlanWorkOutDetails.is_recovery === 1,
                coachNotes: editPlanWorkOutDetails.coach_notes || '',
                rValue: editPlanWorkOutDetails.r_value || '',
                rType: editPlanWorkOutDetails.r_type || 0,
                rComment: editPlanWorkOutDetails.r_comment || '',
            });
        } else {
            formik.resetForm();
        }
    }, [editPlanWorkOutDetails]);
    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "20px",
                        padding: '20px 30px',
                        height: "auto",
                        overflow: "scroll",
                        "::-webkit-scrollbar": { width: "0px", height: '0px' }
                    },
                    "& .MuiDialogContent-root": {
                        overflowY: "initial",
                    },
                    "& .MuiBackdrop-root": {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    }
                }}
            >
                <form onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography sx={{ fontSize: '20px', fontWeight: '500', color: '#101010', fontFamily: "Outfit" }}>Add Workout</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}>
                                <IconButton sx={{
                                    width: '30px',
                                    height: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#101010',
                                    borderRadius: '8px',
                                    "&:hover": {
                                        backgroundColor: '#101010',
                                        color: '#fff',
                                    }
                                }} onClick={handleOnClose}>
                                    <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        padding: '20px 0px 0px 0px',
                    }}>
                        <Grid spacing={2} container>
                            <Grid item xs={12}>
                                <CustomDropDown
                                    required={true}
                                    Title={"Select Type"}
                                    value={formik.values.selectTypeDaily}
                                    onChange={formik.handleChange}
                                    name='selectTypeDaily'
                                    placeholder={'Select Type'}
                                    menuList={typeDaily}
                                    NoData='No Type Available'
                                    isLoading={isLoading}
                                    getLabel={(item) => item.name}
                                    errors={formik.touched.selectTypeDaily && formik.errors.selectTypeDaily}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    required={true}
                                    label="Value"
                                    type="number"
                                    min="0"
                                    max={1}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(evt.key) &&
                                        evt.preventDefault()
                                    }
                                    placeholder="Value"
                                    name="value"
                                    value={formik.values.value}
                                    onBlur={formik.handleBlur}
                                    onKeyUp={formik.handleBlur}
                                    errors={formik.touched.value && formik.errors.value}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value.trimStart() !== value) {
                                            e.target.value = value.trimStart();
                                        }
                                        formik.handleChange(e);
                                    }}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            borderRadius: "10px",
                                            border: formik.errors.value ? "1px solid red" : "1px solid #BB9EFF",
                                            color: "black",
                                            fontSize: "18px",
                                            background: "white",
                                            fontFamily: "Outfit",
                                            height: '54px'
                                        },
                                    }}
                                    style={{ color: "black", fontSize: "18px" }}
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    required={true}
                                    label="Sets"
                                    type="number"
                                    min="0"
                                    max={1}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(evt.key) &&
                                        evt.preventDefault()
                                    }
                                    placeholder="Sets"
                                    name="sets"
                                    value={formik.values.sets}
                                    onBlur={formik.handleBlur}
                                    onKeyUp={formik.handleBlur}
                                    errors={formik.touched.sets && formik.errors.sets}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value.trimStart() !== value) {
                                            e.target.value = value.trimStart();
                                        }
                                        formik.handleChange(e);
                                    }}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            borderRadius: "10px",
                                            border: formik.errors.sets ? "1px solid red" : "1px solid #BB9EFF",
                                            color: "black",
                                            fontSize: "18px",
                                            background: "white",
                                            fontFamily: "Outfit",
                                            height: '54px'
                                        },
                                    }}
                                    style={{ color: "black", fontSize: "18px" }}
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomDropDown
                                    required={true}
                                    Title={"Type"}
                                    name='type'
                                    value={formik.values.type}
                                    onChange={formik.handleChange}
                                    placeholder={'Select Type'}
                                    menuList={workOutTypeList}
                                    NoData='No Type Available'
                                    isLoading={isLoading}
                                    getLabel={(item) => item.name}
                                    errors={formik.touched.type && formik.errors.type}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    label="Comment"
                                    type="text"
                                    placeholder="Comment"
                                    name="comment"
                                    multiline
                                    rows={2}
                                    value={formik.values.comment}
                                    onBlur={formik.handleBlur}
                                    errors={formik.touched.comment && formik.errors.comment}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value.trimStart() !== value) {
                                            e.target.value = value.trimStart();
                                        }
                                        formik.handleChange(e);
                                    }}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            borderRadius: "10px",
                                            border: formik.errors.comment ? "1px solid red" : "1px solid #BB9EFF",
                                            color: "black",
                                            fontSize: "18px",
                                            background: "white",
                                            fontFamily: "Outfit",
                                        },
                                    }}
                                    style={{ color: "black", fontSize: "18px" }}
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomCheckBox
                                    label="Recovery Mode"
                                    name="checkRecovery"
                                    checked={formik.values.checkRecovery}
                                    onChange={formik.handleChange}
                                    errors={formik.touched.checkRecovery && formik.errors.checkRecovery} />
                            </Grid>
                            {formik.values.checkRecovery && <>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        required={true}
                                        label="Value"
                                        type="number"
                                        min="0"
                                        max={1}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        onKeyDown={(evt) =>
                                            ["e", "E", "+", "-"].includes(evt.key) &&
                                            evt.preventDefault()
                                        }
                                        placeholder="Value"
                                        name="rValue"
                                        value={formik.values.rValue}
                                        onBlur={formik.handleBlur}
                                        onKeyUp={formik.handleBlur}
                                        errors={formik.touched.rValue && formik.errors.rValue}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            console.log('value', e);

                                            if (value.trimStart() !== value) {
                                                e.target.value = value.trimStart();
                                            }
                                            formik.handleChange(e);
                                        }}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                borderRadius: "10px",
                                                border: formik.errors.rValue ? "1px solid red" : "1px solid #BB9EFF",
                                                color: "black",
                                                fontSize: "18px",
                                                background: "white",
                                                fontFamily: "Outfit",
                                                height: '54px'
                                            },
                                        }}
                                        style={{ color: "black", fontSize: "18px" }}
                                        isLoading={isLoading}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomDropDown
                                        required={true}
                                        Title={"Type"}
                                        value={formik.values.rType}
                                        onChange={formik.handleChange}
                                        placeholder={'Select Type'}
                                        menuList={workOutTypeList}
                                        name='rType'
                                        NoData='No Type Available'
                                        isLoading={isLoading}
                                        getLabel={(item) => item.name}
                                        errors={formik.touched.rType && formik.errors.rType}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        label="Comment"
                                        type="text"
                                        placeholder="Comment"
                                        name="rComment"
                                        multiline
                                        rows={2}
                                        value={formik.values.rComment}
                                        onBlur={formik.handleBlur}
                                        errors={formik.touched.rComment && formik.errors.rComment}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (value.trimStart() !== value) {
                                                e.target.value = value.trimStart();
                                            }
                                            formik.handleChange(e);
                                        }}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                borderRadius: "10px",
                                                border: formik.errors.rComment ? "1px solid red" : "1px solid #BB9EFF",
                                                color: "black",
                                                fontSize: "18px",
                                                background: "white",
                                                fontFamily: "Outfit",
                                            },
                                        }}
                                        style={{ color: "black", fontSize: "18px" }}
                                        isLoading={isLoading}
                                    />
                                </Grid>
                            </>}
                            <Grid item xs={12}>
                                <CustomTextField
                                    label="Coach Notes"
                                    type="text"
                                    placeholder="Coach Notes"
                                    name="coachNotes"
                                    multiline
                                    rows={3}
                                    value={formik.values.coachNotes}
                                    onBlur={formik.handleBlur}
                                    errors={formik.touched.coachNotes && formik.errors.coachNotes}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value.trimStart() !== value) {
                                            e.target.value = value.trimStart();
                                        }
                                        formik.handleChange(e);
                                    }}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            borderRadius: "10px",
                                            border: formik.errors.value ? "1px solid red" : "1px solid #BB9EFF",
                                            color: "black",
                                            fontSize: "18px",
                                            background: "white",
                                            fontFamily: "Outfit",
                                        },
                                    }}
                                    style={{ color: "black", fontSize: "18px" }}
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomSubmitButton
                                    btnTitle={"Done"}
                                    loading={isLoading}
                                    type="submit"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Dialog>
        </Fragment>

    );
};

export default AddWorkoutModel;