import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomThumbUpload from "../../../Components/CustomFileUpload/UploadImage";
import CustomSubmitButton from "../../../Components/CustomSubmitButton/CustomSubmitButton";
import AxiosInstance from "../../../Config/AxiosInstance";
import APILIST from "../../../Config/ApiList";
import Constant from "../../../Utils/Constant";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import CustomDateTimePicker from "../../../Components/CustomDatePicker/CustomDatePicker";
import CUstomTimePicker from "../../../Components/CustomTimePicker/CustomTimePicker";
import moment from "moment";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ChallengesEventList from "./ChallengesEventList";
import AssignWorkoutModel from "./AssignWorkoutModel";
import useDebounce from "../../../Components/useDebounce/useDebounce";
const AddChallengesEvent = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedThumb, setSelectedThumb] = useState(null);
  const [selectedThumbFile, setSelectedThumbFile] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedBannerFile, setSelectedBannerFile] = useState(null);
  const [isSelectedWorkoutModel, setIsSelectedWorkoutModel] = useState(false);
  const [isChallengesEventDetails, setIsChallengesEventDetails] = useState(null);
  const [checkedWorkOut, setCheckedWorkOut] = useState({});
  const [searchWorkOut, setSearchWorkOut] = useState("");
  const debouncedSearch = useDebounce(searchWorkOut, 500);
  const [isWorkoutDetailsList, setIsWorkoutDetailsList] = useState([]);
  const [typeCheck, setTypeCheck] = useState(null);
  const typeList = [
    {
      id: "1",
      name: "Challenges",
      label: "Challenges",
    },
    {
      id: "2",
      name: "Events",
      label: "Events",
    },
  ];
  const handleOnChangeThumb = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(
          file.type
        )
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedThumbFile(file);
          setSelectedThumb(videoUrl);
          formik.setFieldValue("thumb", file);
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload Only(PNG.JPG,JPEG)");
      }
    }
  }, []);
  const handleOnChangeBanner = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(
          file.type
        )
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedBannerFile(file);
          setSelectedBanner(videoUrl);
          formik.setFieldValue("badge", file);
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload Only(PNG.JPG,JPEG)");
      }
    }
  }, []);
  const getByIdChallengesEventDetails = async (EventChallengesPID) => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(APILIST.GET_DETAILS_EVENT_CHALLENGES, {
        params: {
          id: EventChallengesPID,
        }
      });
      if (response && response?.data?.s) {
        const resData = response?.data?.r;
        formik.setValues({
          type: resData?.type || 0,
          title: resData?.title || "",
          planDuration: resData?.week || "",
          description: resData?.description || "",
          location: resData?.location || "",
          selectDate: dayjs(resData?.date) || dayjs(),
          selectTime: dayjs(resData?.time, "HH:mm:ss") || dayjs(),
          thumb: resData?.cover_img || null,
          badge: resData?.badge || null,
        });
        setIsChallengesEventDetails(resData);
        if (resData?.badge) {
          setSelectedThumb(`${Constant.BASE_URL}${resData?.badge}`);
        }
        if (resData?.cover_img) {
          setSelectedBanner(`${Constant.BASE_URL}${resData?.cover_img}`);
        }
        getByIdWorkOutDetails(resData);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }
  const getByIdWorkOutDetails = async (challengesEventDetails) => {
    try {
      const response = await AxiosInstance.get(APILIST.GET_ALL_WORKOUTS, {
        params: {
          week: challengesEventDetails?.week,
          challenge_event_id: challengesEventDetails?.id,
          search: debouncedSearch,
          count: page * pageSize,
          offset: pageSize,
        }
      });
      if (response && response?.data?.s) {
        const resData = response?.data?.r;
        const Rowdata = resData?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setCount(response?.data?.c || 0);
        setIsWorkoutDetailsList(Rowdata);
        const initialCheckedRights = {};
        const selectedModuleIds = [];
        const selectedAccessRightIds = [];
        if (Array.isArray(resData)) {
          resData.forEach((right) => {
            initialCheckedRights[`${right.id}`] = right.is_added === 1;
            if (right.is_added === 1) {
              selectedModuleIds.push(`${right.id}`);
            }
            if (Array.isArray(right.access)) {
              right.access.forEach((accessRight) => {
                console.log('accessRight', accessRight);

                initialCheckedRights[`access-${accessRight.id}`] = accessRight.is_added !== null;
                if (accessRight.admin_user_id !== null) {
                  selectedAccessRightIds.push(`${accessRight.id}`);
                }
              });
            }
          });
        }
        setCheckedWorkOut(initialCheckedRights);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }
  const handleOnClickWorkOutIsAdded = async (checkedWorkOut) => {
    const selectedWorkoutIDs = Object.keys(checkedWorkOut).filter(id => checkedWorkOut[id]);
    if (isChallengesEventDetails !== null) {
      const commaSeparatedIds = selectedWorkoutIDs.join(',');
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append('id', isChallengesEventDetails?.id);
        formData.append('workout_ids', commaSeparatedIds);
        const response = await AxiosInstance.post(APILIST.UPDATE_EVENTS, formData);

        if (response?.data?.s === 1) {
          const runnerPID = window.sessionStorage.getItem('ChallengesEventID');
          if (runnerPID) {
            await getByIdChallengesEventDetails(runnerPID);
          }
          setIsSelectedWorkoutModel(false);
        }
      } catch (error) {
        console.error('Error in handleOnClickWorkOutIsAdded:', error);
        toast.error("An error occurred while updating the plan. Please check your network and try again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("Please create and publish your plan before proceeding.");
    }
  };
  const handleOnClickPublish = async () => {
    if (isChallengesEventDetails !== null) {
      const checkWorkout = isChallengesEventDetails?.challenge_event_details || [];
      if (checkWorkout && checkWorkout?.length > 0) {
        toast.success("Challenges && Event details updated successfully");
        sessionStorage.clear();
        navigate('/admin/challenges-events');
      } else {
        toast.error("Please create and publish your plan before proceeding.");
      }
    }
  };
  useEffect(() => {
    const ChallengesEventID = location?.state?.ChallengesEventID;
    if (ChallengesEventID) {
      window.sessionStorage.setItem('ChallengesEventID', ChallengesEventID);
      getByIdChallengesEventDetails(ChallengesEventID);
      setTypeCheck("Edit")
    }
  }, [location.state?.ChallengesEventID]);
  useEffect(() => {
    const ChallengesEventID = window.sessionStorage.getItem('ChallengesEventID');
    if (ChallengesEventID) {
      getByIdChallengesEventDetails(ChallengesEventID);
    }
  }, []);
  useEffect(() => {
    if (isChallengesEventDetails) {
      getByIdWorkOutDetails(isChallengesEventDetails, debouncedSearch);
    }
  }, [debouncedSearch, isChallengesEventDetails, page]);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const formik = useFormik({
    initialValues: {
      type: 0,
      title: "",
      planDuration: "",
      description: "",
      location: "",
      selectDate: dayjs(),
      selectTime: dayjs(),
      thumb: null,
      badge: null,
    },
    validationSchema: Yup.object().shape({
      type: Yup.number()
      .required("Plan level is required!")
      .oneOf([1, 2], "Plan level is required!"),
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      location: Yup.string().required("Location is required"),
      thumb: Yup.string().required("Thumb is required"),
      badge: Yup.string().required("Thumb is required"),
      selectDate: Yup.mixed().required("Please enter date here"),
      selectTime: Yup.mixed().required("Please enter time here"),
      planDuration: Yup.number()
        .required("Plan duration is required")
        .test("duration-when-type-1", "Duration must be 1 when type is Challenges", function (value) {
          const { type } = this.parent;
          if (type === 1 && value !== 1) {
            return false;
          }
          return true;
        }),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        try {
          const formData = new FormData();

          if (isChallengesEventDetails) {
            formData.append("id", isChallengesEventDetails?.id);
          }

          formData.append("title", values.title);
          formData.append("type", values.type);
          formData.append("description", values.description);
          formData.append("location", values.location);
          formData.append('week', values.planDuration);
          const formattedDate = moment(values.selectDate).format("YYYY-MM-DD");
          formData.append("date", formattedDate);
          const formattedTime = moment(values.selectTime).format("HH:mm:ss");
          formData.append("time", formattedTime);
          if (selectedThumbFile) {
            formData.append("cover_img", values.thumb);
          }
          if (selectedBannerFile) {
            formData.append("badge", values.badge);
          }
          const apiEndpoint = isChallengesEventDetails
            ? APILIST.UPDATE_EVENTS
            : APILIST.CREATE_EVENTS;
          const response = await AxiosInstance.post(apiEndpoint, formData);
          if (response && response.data?.s) {
            const challengesEID = isChallengesEventDetails ? window.sessionStorage.getItem('ChallengesEventID') : response?.data?.r?.id;
            toast.success(
              isChallengesEventDetails
                ? "Updated Successfully!"
                : "Added Successfully!"
            );
            resetForm();
            setSelectedThumb(null);
            setSelectedThumbFile(null);
            window.sessionStorage.setItem('ChallengesEventID', challengesEID);
            getByIdChallengesEventDetails(challengesEID);

          } else {
            toast.error("An unexpected error occurred");
          }
        } catch (error) {
          toast.error(error.message || "An error occurred");
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <Fragment>
      <Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        top: '0px',
        left: '0px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
        padding: '10px',
        zIndex: '2'
      }}>
        <IconButton sx={{
          width: '40px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#101010',
          borderRadius: '8px',
          "&:hover": {
            backgroundColor: '#101010',
            color: '#fff',
          }
        }} onClick={() => {
          navigate(-1);
        }}>
          <ArrowBackIosNewIcon sx={{ color: '#fff', fontSize: '28px' }} />
        </IconButton>
        <Typography
          sx={{
            fontSize: { xs: "20px", md: "28px" },
            fontWeight: "500",
            paddingBottom: "10px",
            fontFamily: "Outfit",
          }}
        >
          {isChallengesEventDetails ? "Update" : "Add"} Challenges & Event
        </Typography>
        <CustomSubmitButton
          btnTitle={"Publish"}
          type="submit"
          sx={{ width: '130px' }}
          loading={isLoading}
          onSubmit={() => handleOnClickPublish()}
        />
      </Box>
      <Box sx={{
        width: '98%',
        margin: "0px auto",
        padding: '100px 0px 30px 0px',

      }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box sx={{
              background: '#EFE9FF80',
              padding: { xs: "15px", lg: "15px" },
              borderRadius: '8px'
            }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <Box
                  sx={{
                    padding: "20px 0px 0px 0px",
                  }}
                >
                  <Grid spacing={2} container>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "400",
                          textAlign: "left",
                          fontSize: { xs: "16px", sm: "18px" },
                          mb: "8px",
                        }}
                      >
                        Thumbnail
                      </Typography>
                      <CustomThumbUpload
                        sx={{ height: "150px" }}
                        accept="image/*"
                        onDrop={handleOnChangeThumb}
                        selectedFile={selectedThumb}
                        onRemove={(e) => {
                          e.stopPropagation();
                          setSelectedThumb(null);
                          setSelectedThumbFile(null);
                          formik.setFieldValue("thumb", null);
                        }}
                        isLoading={isLoading}
                        errors={formik.errors.thumb}
                      />
                      {formik.touched.thumb && formik.errors.thumb ? (
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "400",
                            fontSize: { xs: "14px", sm: "14px" },
                            marginTop: "5px",
                          }}
                        >
                          {formik.errors.thumb}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "400",
                          textAlign: "left",
                          fontSize: { xs: "16px", sm: "18px" },
                          mb: "8px",
                        }}
                      >
                        Banner
                      </Typography>
                      <CustomThumbUpload
                        sx={{ height: "150px" }}
                        accept="image/*"
                        onDrop={handleOnChangeBanner}
                        selectedFile={selectedBanner}
                        onRemove={(e) => {
                          e.stopPropagation();
                          setSelectedBanner(null);
                          setSelectedBannerFile(null);
                          formik.setFieldValue("badge", null);
                        }}
                        isLoading={isLoading}
                        errors={formik.errors.badge}
                      />
                      {formik.touched.badge && formik.errors.badge ? (
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "400",
                            fontSize: { xs: "14px", sm: "14px" },
                            marginTop: "5px",
                          }}
                        >
                          {formik.errors.badge}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "400",
                          textAlign: "left",
                          fontSize: { xs: "16px", sm: "18px" },
                          mb: "8px",
                        }}
                      >
                        Date
                      </Typography>
                      <CustomDateTimePicker
                        name="selectDate"
                        value={formik.values.selectDate}
                        onChange={(newValue) => {
                          formik.setFieldValue("selectDate", newValue);
                        }}
                        errors={formik.touched.selectDate && formik.errors.selectDate}
                        sx={{
                          width: "100%",
                          borderRadius: "10px",
                          color: "black",
                          fontSize: "18px",
                          background: "white",
                          fontFamily: "Outfit",
                          outline:
                            formik.touched.selectTime && formik.errors.selectTime
                              ? "1px solid red"
                              : "1px solid #D0BCFF",
                          ".MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            border: "none",
                          },
                        }}
                        isLoading={isLoading}
                      />
                      {formik.touched.selectDate && formik.errors.selectDate ? (
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "400",
                            fontSize: { xs: "14px", sm: "14px" },
                            marginTop: "5px",
                            paddingLeft: "10px",
                          }}
                        >
                          {formik.errors.selectDate}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "400",
                          textAlign: "left",
                          fontSize: { xs: "16px", sm: "18px" },
                          mb: "8px",
                        }}
                      >
                        Time
                      </Typography>
                      <CUstomTimePicker
                        name="selectTime"
                        inputFormat="hh:mm:ss"
                        value={formik.values.selectTime}
                        onChange={(newValue) => {
                          formik.setFieldValue("selectTime", newValue);
                        }}
                        errors={formik.touched.selectTime && formik.errors.selectTime}
                        sx={{
                          width: "100%",
                          borderRadius: "10px",
                          color: "black",
                          fontSize: "18px",
                          background: "white",
                          fontFamily: "Outfit",
                          outline:
                            formik.touched.selectTime && formik.errors.selectTime
                              ? "1px solid red"
                              : "1px solid #D0BCFF",
                          ".MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            border: "none",
                          },
                        }}
                      />
                      {formik.touched.selectTime && formik.errors.selectTime ? (
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "400",
                            fontSize: { xs: "14px", sm: "14px" },
                            marginTop: "5px",
                            paddingLeft: "10px",
                          }}
                        >
                          {formik.errors.selectTime}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <CustomDropDown
                        Title={"Type"}
                        name="type"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        placeholder={"Select Type"}
                        menuList={typeList}
                        onBlur={formik.handleBlur}
                        getLabel={(item) => item}
                        onKeyUp={formik.handleBlur}
                        errors={formik.touched.type && formik.errors.type}
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "10px",
                            border: formik.errors.title
                              ? "1px solid red"
                              : "1px solid #BB9EFF",
                            color: "black",
                            fontSize: "18px",
                            background: "white",
                            fontFamily: "Outfit",
                          },
                        }}
                        style={{ color: "black", fontSize: "18px" }}
                        isLoading={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CustomTextField
                        label="Duration"
                        type="number"
                        min="0"
                        max={1}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        placeholder="Duration"
                        name="planDuration"
                        value={formik.values.planDuration}
                        onBlur={formik.handleBlur}
                        onKeyUp={formik.handleBlur}
                        errors={formik.touched.planDuration && formik.errors.planDuration}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.trimStart() !== value) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "10px",
                            border: formik.errors.planDuration ? "1px solid red" : "1px solid #BB9EFF",
                            color: "black",
                            fontSize: "18px",
                            background: "white",
                            fontFamily: "Outfit",
                            height: '56px'
                          },
                        }}
                        style={{ color: "black", fontSize: "18px" }}
                        isLoading={isLoading}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <CustomTextField
                        label="Title"
                        type="text"
                        placeholder="Title"
                        name="title"
                        value={formik.values.title}
                        onBlur={formik.handleBlur}
                        onKeyUp={formik.handleBlur}
                        errors={formik.touched.title && formik.errors.title}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.trimStart() !== value) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "10px",
                            border: formik.errors.title
                              ? "1px solid red"
                              : "1px solid #BB9EFF",
                            color: "black",
                            fontSize: "18px",
                            background: "white",
                            fontFamily: "Outfit",
                          },
                        }}
                        style={{ color: "black", fontSize: "18px" }}
                        isLoading={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <CustomTextField
                        label="Location"
                        type="text"
                        placeholder="Locaion"
                        name="location"
                        value={formik.values.location}
                        onBlur={formik.handleBlur}
                        onKeyUp={formik.handleBlur}
                        errors={formik.touched.location && formik.errors.location}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.trimStart() !== value) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "10px",
                            border: formik.errors.location
                              ? "1px solid red"
                              : "1px solid #BB9EFF",
                            color: "black",
                            fontSize: "18px",
                            background: "white",
                            fontFamily: "Outfit",
                          },
                        }}
                        style={{ color: "black", fontSize: "18px" }}
                        isLoading={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <CustomTextField
                        label="Description"
                        type="text"
                        placeholder="Description"
                        name="description"
                        value={formik.values.description}
                        onBlur={formik.handleBlur}
                        onKeyUp={formik.handleBlur}
                        errors={formik.touched.description && formik.errors.description}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.trimStart() !== value) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                        multiline={true}
                        rows={3}
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "10px",
                            border: formik.errors.description
                              ? "1px solid red"
                              : "1px solid #BB9EFF",
                            color: "black",
                            fontSize: "18px",
                            background: "white",
                            fontFamily: "Outfit",
                          },
                        }}
                        style={{ color: "black", fontSize: "18px" }}
                        isLoading={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomSubmitButton
                        btnTitle={isChallengesEventDetails ? "Update" : 'Add'}
                        loading={isLoading}
                        type="submit"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Box>
          </Grid>
          {window.sessionStorage.getItem('ChallengesEventID') && <Grid item xs={12} md={7}>
            <Box sx={{
              background: '#EFE9FF80',
              padding: { xs: "15px", lg: "20px" },
              borderRadius: '8px'
            }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%'
                  }}>
                    <Button
                      sx={{
                        width: "190px",
                        borderRadius: "15px",
                        textTransform: "none",
                        fontSize: "16px",
                        background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                        color: "#fff",
                        padding: "0",
                        margin: "0",
                        height: '51px',
                        "&:hover": {
                          background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                          color: "black",
                        },
                        fontFamily: "Outfit",
                      }}
                      onClick={() => setIsSelectedWorkoutModel(true)}
                    >
                      Add WorkOut Details
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <ChallengesEventList
                    isRunnerPlanDetails={isChallengesEventDetails}
                  />
                </Grid>
              </Grid>
            </Box>
            <AssignWorkoutModel
              open={isSelectedWorkoutModel}
              onClose={() => { setIsSelectedWorkoutModel(false); }}
              handleOnClose={() => { setIsSelectedWorkoutModel(false) }}
              isWorkoutDetailsList={isWorkoutDetailsList}
              checkedWorkOut={checkedWorkOut}
              setCheckedWorkOut={setCheckedWorkOut}
              searchWorkOut={searchWorkOut}
              setSearchWorkOut={setSearchWorkOut}
              handleOnClickWorkOutIsAdded={handleOnClickWorkOutIsAdded}
              pageSize={pageSize}
              page={page}
              count={count}
              handlePageChange={handlePageChange}
            />
          </Grid>}
        </Grid>
      </Box>

    </Fragment>

  );
};

export default AddChallengesEvent;
