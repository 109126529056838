import React, { Fragment} from "react";
import { Routes, Route } from "react-router-dom";
import ToastMessage from "./Components/ToastMessage/ToastMessage";
import 'react-loading-skeleton/dist/skeleton.css'
import routes from "./Routes/Routes";
import "./App.css";
const App = () => {
  return (
    <Fragment>
        <ToastMessage />
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={route.component}
                  exact={route.exact}
                  authenticate={route.authenticate}
                />
              );
            })}
          </Routes>
    </Fragment>
  );
};

export default App;
