import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ChatList from "./UsersList";
import UserProfileTop from "./UserProfileTop";
import UserDetails from "./UserDetails";
import UserSendTextField from "./UserSendTextField";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { DATABASE, STORAGE } from "../../../Config/firebase";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
const Support = () => {
  const [messages, setMessages] = useState([]);
  const [textMessage, setTextMessage] = useState("");
  const [selectedChat, setSelectedChat] = useState(null);
  const [replyMessage, setReplyMessage] = useState(null);
  const [uploadImageModel, setUploadImageModel] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageSrcFile, setImageSrcFile] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (selectedChat) {
      const q = query(collection(DATABASE, "support", selectedChat?.id, "support_chat"),orderBy("date", "asc"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(messages);
      });

      return () => unsubscribe();
    }
  }, [selectedChat]);
  const handleSendMessage = useCallback(async () => {
    setIsLoading(true);
    try {
      let imageDownloadURL = null;
      if (imageSrcFile) {
        const imageRef = ref(STORAGE, `chat_images/${uuidv4()}`);
        await uploadBytes(imageRef, imageSrcFile);
        imageDownloadURL = await getDownloadURL(imageRef);
      }
      await addDoc(
        collection(DATABASE, "support", selectedChat?.id, "support_chat"),
        {
          msg: textMessage,
          date: new Date(),
          last_msg: textMessage,
          msg_type: imageSrcFile ? 2 : 1,
          reference_chat_id: replyMessage?.id || null,
          unread_count: 0,
          user_id: 0,
          isSent: true,
          imageUrl: imageDownloadURL || null,
        }
      );
      await updateDoc(doc(DATABASE, "support", selectedChat?.id), {
        last_msg: textMessage,
        last_msg_date: new Date(),
      });
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);
    } finally {
      setTextMessage("");
      setImageSrc(null);
      setImageSrcFile(null);
      setReplyMessage(null);
      setIsLoading(false);
    }
  }, [textMessage, imageSrcFile, replyMessage, selectedChat]);

  const onEmojiClick = (event) => {
    setTextMessage((prevMessage) => prevMessage + event.emoji);
    setShowEmojiPicker(false);
  };
  const handleImageSelect = (url) => {
    setImageSrcFile(url?.file);
    setImageSrc(url?.videoUrl);
  };
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      <Box
        sx={{
          display: "flex",
          height: '100vh',
          gridGap: "20px",
        }}
      >
        <ChatList setSelectedChat={setSelectedChat} />
        <Box
          sx={{
            width: "calc(100% - 350px)",
            background: "#EFE9FF80",
            // padding: "20px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <UserProfileTop selectedChat={selectedChat} />
          <Box sx={{
          }}>
            <UserDetails
              messages={messages}
              selectedChat={selectedChat}
              setReplyMessage={setReplyMessage}
              replyMessage={replyMessage}
            />
            <UserSendTextField
              textMessage={textMessage}
              value={textMessage}
              onChange={(e) => {
                const { value } = e.target;
                const trimmedValue = value.trimStart();
                setTextMessage(trimmedValue);
              }}
              imageSrc={imageSrc}
              replyMessage={replyMessage}
              onCloseReply={() => setReplyMessage(null)}
              onEmojiClick={onEmojiClick}
              showEmojiPicker={showEmojiPicker}
              toggleEmojiPicker={toggleEmojiPicker}
              handleSendMessage={handleSendMessage}
              isLoading={isLoading}
              handleImageSelect={handleImageSelect}
              uploadImageModel={uploadImageModel}
              setUploadImageModel={setUploadImageModel}
              selectedChat={selectedChat}
              onKeyDown={(e) => {
                if (e.key === "Enter" && (textMessage || imageSrc)) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Support;
