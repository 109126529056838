import { Box, Grid, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import moment from 'moment';
import Constant from '../../../Utils/Constant';
const UserProfile = ({ chatList, handleSelectChat, isSelected }) => {
  console.log('chatList', chatList);
  console.log('isSelected', isSelected);
  
  const [userProfileData, setUserProfileData] = useState({});
  const timestamp = new Date((chatList.date.seconds * 1000) + (chatList.date.nanoseconds / 1000000));
  const getInitials = (name) => {
    if (!name) return '';
    const parts = name.split(' ');
    const firstTwoParts = parts.slice(0, 2);
    const initials = firstTwoParts.map(part => part.charAt(0).toUpperCase()).join('');
  
    return initials;
  };
  const getChatListLightWeight = async (id) => {
    const response = await AxiosInstance.get(APILIST.GET_BY_ID, {
      params: {
        user_id: id
      }
    });
    if (response && response?.data?.r) {
      setUserProfileData(response?.data?.r)
    }
  }
  useEffect(() => {
    getChatListLightWeight(chatList?.id);
  }, [chatList?.id])
  return (
    <Fragment>
      <Grid item xs={12}>
        <Box onClick={() => handleSelectChat(userProfileData)} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '10px',
          borderRadius: '14px',
          background: isSelected ? '#fff' : "transparent",
        }}>
          <Box sx={{
            width: '60px',
            height: '60px',
            borderRadius: '50%'
          }}>
            {userProfileData?.profile_img ? (<img src={`${Constant.BASE_URL}${userProfileData?.profile_img}`} alt='userProfile' style={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              objectFit: 'cover'
            }} />) : (<Typography sx={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              backgroundColor: '#ddd',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px',
              color: '#000000',
              fontFamily: "Outfit",
              fontWeight: '600',
              textTransform: 'capitalize'
            }}>{getInitials(userProfileData?.user_name)}</Typography>)}
          </Box>
          <Box sx={{
            width: 'calc(100% - 140px)',
            margin: '0px 10px 0px 10px'
          }}>
            <Typography sx={{
              fontSize: '18px',
              fontWeight: '600',
              color: '#000000',
              fontFamily: 'Outfit',
              textTransform: 'capitalize',
            }}>{userProfileData?.user_name?.length > 17 ? userProfileData?.user_name.slice(0, 17) + "..." : userProfileData?.user_name}</Typography>
            <Typography sx={{
              width: "100%",
              fontSize: '18px',
              color: '#000000B2',
              fontFamily: "Outfit",
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              padding: '4px 0px 0px 0px',
              fontWeight: '400'
            }}>{chatList?.last_msg}
            </Typography>
          </Box>
          <Box sx={{
            width: '60px',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'column'
          }}>
            <Typography sx={{
              fontSize: '16px',
              fontFamily: "Outfit",
              color: '#000000B2',
              fontWeight: '400',
              marginBottom: '8px'
            }}>
              {moment(timestamp).format('D MMM')}
            </Typography>
            {chatList?.unread_count > 0 && (
              <Typography sx={{
                width: '23px',
                height: '23px',
                fontSize: '16px',
                fontFamily: "Outfit",
                color: '#000000B2',
                fontWeight: '400',
                background: '#B190FF',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                {chatList.unread_count}
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>


    </Fragment>
  )
}

export default UserProfile