import React, { createContext, useState, ReactNode } from "react";
const initialState = {
    isAdmin: null,
    setIsAdmin: () => {},
};

export const StateStore = createContext(initialState);

const StateStoreProvider= ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(initialState.isAdmin);
  return (
    <StateStore.Provider value={{ isAdmin, setIsAdmin }}>
      {children}
    </StateStore.Provider>
  );
};

export default StateStoreProvider;
