import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import CustomThumbUpload from '../../../Components/CustomFileUpload/UploadImage';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import Constant from '../../../Utils/Constant';
const AddPartnerModel = (props) => {
    const { open, onClose, typeCheck, editPartnerData, handleOnSubmit, handleOnClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedThumb, setSelectedThumb] = useState(null);
    const [selectedThumbFile, setSelectedThumbFile] = useState(null);
    const handleOnChangeThumb = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error("Invalid file type for thumb!");
        } else {
            const file = acceptedFiles[0];
            if (file && ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(file.type)) {
                if (file.size <= 2 * 1024 * 1024) {
                    const videoUrl = URL.createObjectURL(file);
                    setSelectedThumbFile(file);
                    setSelectedThumb(videoUrl);
                    formik.setFieldValue('thumb', file);
                } else {
                    toast.error(`File size exceeds the limit of 2MB`);
                }
            } else {
                toast.error("Upload Only(PNG.JPG,JPEG)");
            }
        }
    }, []);
    useEffect(() => {
        if (typeCheck === 'EDIT' && editPartnerData) {
            formik.setValues({
                title: editPartnerData?.title || "",
                link: editPartnerData?.link || "",
                thumb: editPartnerData?.banner || null
            })
            if (editPartnerData && editPartnerData?.banner) {
                setSelectedThumb(`${Constant.BASE_URL}${editPartnerData?.banner}`);
            }
        } else {
            formik.resetForm();
            setSelectedThumb(null);
            setSelectedThumbFile(null);
        }
    }, [typeCheck, editPartnerData]);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: "",
            link: "",
            thumb: null,
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Title is required"),
            link: Yup.string().required("Link is required").matches(Constant.REGEX.URL, "Please Provide Valid Link"),
            thumb: Yup.string().required("Thumb is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            if (isLoading) {
                return;
            } else {
                try {
                    const formData = new FormData();
                    if (editPartnerData) {
                        formData.append('id', editPartnerData?.id);
                    }
                    formData.append('title', values.title);
                    formData.append('link', values.link);
                    if (selectedThumbFile) {
                        formData.append('banner', values.thumb);
                    }
                    const apiEndpoint = editPartnerData ? APILIST.EDIT_PARTNER : APILIST.ADD_PARTNER;
                    const response = await AxiosInstance.post(apiEndpoint, formData);

                    if (response && response.data?.s) {
                        toast.success(editPartnerData ? 'Partner Updated Successfully!' : 'Partner Added Successfully!');
                        resetForm();
                        setSelectedThumb(null);
                        setSelectedThumbFile(null);
                        handleOnSubmit()
                    } else {
                        toast.error('An unexpected error occurred');
                    }
                } catch (error) {
                    toast.error(error.message || 'An error occurred');
                } finally {
                    setIsLoading(false);
                }
            }
        },
    });
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    padding: '20px 30px',
                    height: "600px",
                    overflow: "scroll",
                    "::-webkit-scrollbar": { width: "0px", height: '0px' }
                },
                "& .MuiDialogContent-root": {
                    overflowY: "initial",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }
            }}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', color: '#101010', fontFamily: "Outfit" }}>{typeCheck ? "Update" : "Add"} Partner</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <IconButton sx={{
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#101010',
                                borderRadius: '8px',
                                "&:hover": {
                                    backgroundColor: '#101010',
                                    color: '#fff',
                                }
                            }} onClick={handleOnClose}>
                                <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    padding: '20px 0px 0px 0px',
                }}>
                    <Grid spacing={2} container>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Title"
                                type="text"
                                placeholder="Title"
                                name="title"
                                value={formik.values.title}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.title && formik.errors.title}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.title ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Link"
                                type="text"
                                placeholder="Link"
                                name="link"
                                value={formik.values.link}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.link && formik.errors.link}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.link ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                    fontSize: { xs: "16px", sm: "18px" },
                                    mb: '8px'
                                }}
                            >
                                Banner
                            </Typography>
                            <CustomThumbUpload
                                accept="image/*"
                                onDrop={handleOnChangeThumb}
                                selectedFile={selectedThumb}
                                onRemove={(e) => {
                                    e.stopPropagation();
                                    setSelectedThumb(null);
                                    setSelectedThumbFile(null);
                                    formik.setFieldValue('thumb', null)
                                }}
                                typeCheck={typeCheck}
                                isLoading={isLoading}
                                errors={formik.errors.thumb}
                            />
                            {formik.touched.thumb && formik.errors.thumb ? (
                                <Typography
                                    sx={{
                                        color: "red",
                                        fontWeight: "400",
                                        fontSize: { xs: "14px", sm: "14px" },
                                        marginTop: "5px",
                                    }}
                                >
                                    {formik.errors.thumb}
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid item xs={12}>
                            <CustomSubmitButton
                                btnTitle={typeCheck ? "Update Partner" : "Add Partner"}
                                loading={isLoading}
                                type="submit"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Dialog>
    );
};

export default AddPartnerModel;