// import React, { Fragment } from 'react';
// import {
//     Box,
//     Typography,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableRow,
//     Paper,
//     TableHead,
// } from '@mui/material';
// import AddWorkOutModel from './AddWorkOutModel';

// const PlanWorkOutList = (props) => {
//     const {
//         isSelectedWorkoutDetails,
//         setIsSelectedWorkOutID,
//         addMoreWorkoutModel,
//         setAddMoreWorkoutModel,
//         isSelectedRunnerPlanDetails,
//         handleOnClickWorkOutIsAdded,
//         checkedWorkOut,
//         setCheckedWorkOut,
//         searchWorkOut,
//         setSearchWorkOut,
//         isRunnerPlanDetails
//     } = props;
//     const filteredWorkouts = isSelectedWorkoutDetails?.filter(workout => workout?.is_added === 1) || [];
//     console.log('isRunnerPlanDetails', isRunnerPlanDetails);

//     return (
//         <Fragment>
//             {filteredWorkouts.length > 0 ? (
//                 <Box sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: '15px', padding: '20px' }}>
//                     <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//                         <Typography sx={{ color: 'black', fontWeight: '400', textAlign: 'left', fontSize: { xs: '16px', sm: '18px' }, mb: '8px' }}>
//                             Workout For {filteredWorkouts.length} {filteredWorkouts.length > 1 ? 'Weeks' : 'week'}
//                         </Typography>
//                     </Box>
//                     <TableContainer component={Paper}>
//                         <Table sx={{ minWidth: 650 }} aria-label="workout table">
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell>Sr No.</TableCell>
//                                     <TableCell>Title</TableCell>
//                                     <TableCell>Week</TableCell>
//                                     <TableCell>Day</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {filteredWorkouts.map((workout, workoutIndex) => (
//                                     <TableRow key={workoutIndex}>
//                                         <TableCell>
//                                             {workoutIndex + 1}
//                                         </TableCell>
//                                         <TableCell>{workout?.title}</TableCell>
//                                         <TableCell>{workout?.week}</TableCell>
//                                         <TableCell>{workout?.day}</TableCell>
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                 </Box>
//             ) : <Typography sx={{
//                 color: 'black',
//                 fontWeight: '400',
//                 textAlign: 'center',
//                 fontSize: { xs: '16px', sm: '18px' },
//                 mb: '8px'
//             }}>No Workout Details Available</Typography>}
//             {/* <AddWorkOutModel
//                 open={addMoreWorkoutModel}
//                 onClose={() => { setAddMoreWorkoutModel(false); }}
//                 handleOnClose={() => { setAddMoreWorkoutModel(false); }}
//                 isSelectedWorkoutDetails={isSelectedWorkoutDetails}
//                 isSelectedRunnerPlanDetails={isSelectedRunnerPlanDetails}
//                 setIsSelectedWorkOutID={setIsSelectedWorkOutID}
//                 handleOnClickWorkOutIsAdded={handleOnClickWorkOutIsAdded}
//                 checkedWorkOut={checkedWorkOut}
//                 setCheckedWorkOut={setCheckedWorkOut}
//                 searchWorkOut={searchWorkOut}
//                 setSearchWorkOut={setSearchWorkOut}
//             /> */}
//         </Fragment>
//     );
// };

// export default PlanWorkOutList;
import React, { Fragment } from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    TableHead,
} from '@mui/material';

const PlanWorkOutList = (props) => {
    const {isRunnerPlanDetails} = props;
    return (
        <Fragment>
            {isRunnerPlanDetails?.plan_details?.length > 0 ? (
                <Box sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: '15px', padding: '20px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ color: 'black', fontWeight: '400', textAlign: 'left', fontSize: { xs: '16px', sm: '18px' }, mb: '8px' }}>
                            Workout For {isRunnerPlanDetails?.plan_details.length} {isRunnerPlanDetails?.plan_details.length > 1 ? 'Weeks' : 'week'}
                        </Typography>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="workout table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr No.</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Week</TableCell>
                                    <TableCell>Day</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isRunnerPlanDetails?.plan_details?.map((workout, workoutIndex) => (
                                    <TableRow key={workoutIndex}>
                                        <TableCell>
                                            {workoutIndex + 1}
                                        </TableCell>
                                        <TableCell>{workout?.title}</TableCell>
                                        <TableCell>{workout?.week}</TableCell>
                                        <TableCell>{workout?.day}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ) : <Typography sx={{
                color: 'black',
                fontWeight: '400',
                textAlign: 'center',
                fontSize: { xs: '16px', sm: '18px' },
                mb: '8px'
            }}>No Workout Details Available</Typography>}
        </Fragment>
    );
};

export default PlanWorkOutList;
