import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { NavMenuList } from "./MenuList";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { signOut } from "firebase/auth";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, ListItemIcon } from "@mui/material";
import Swal from "sweetalert2";
import { AUTH_LOGIN } from '../../Config/firebase';
import LOGO from "../../Assets/Images/Common/logo.png";
import LOGOUT from '../../Assets/Images/Common/Logout.png';
import { StateStore } from '../Middleware/StateContext';
const drawerWidth = 280;

const Sidebar = (props) => {
  const { isAdmin } = React.useContext(StateStore);
  console.log('isAdmin', isAdmin);

  const { element } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const location = useLocation();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      text: "Logging out will end your session and you will be redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await signOut(AUTH_LOGIN);
        localStorage.clear();
        Swal.fire({
          title: "Logged Out",
          text: "You have been successfully logged out. Redirecting to login page...",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          navigate("/login");
        });
      }
    });
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };


  const roleCheck = JSON.parse(localStorage.getItem("TRANSFORMATIONAL_RUNNER"));
  const loginRole = roleCheck?.role;

  let mergedArray;

  if (isAdmin && loginRole !== 2) {
    mergedArray = isAdmin
      ?.filter(item => item?.admin_user_id)
      ?.map(item1 => {
        const item2 = NavMenuList?.find(item => item?.title === item1?.name);
        return { ...item1, ...item2, loginRole };
      });
  } else {
    mergedArray = NavMenuList;
  }
  const drawer = (
    <Box
      sx={{
        bgcolor: "#1B1927",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pt: 2,
          width: '110px',
          margin: '0 auto'
        }}
      >
        <img
          src={LOGO}
          alt="logo"
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{ width: "100%", height: "calc(100vh - 150px)", overflowY: "auto" }}
        className="customSidebarList"
      >
        <List>
          {mergedArray && mergedArray?.map(({ title, path, icon, active }, i) => {
            return (
              <ListItem key={i} disablePadding sx={{ m: "0.5rem 0" }}>
                <Link
                  style={{
                    color: "black",
                    textDecoration: "none",
                    width: "100%",
                  }}
                  to={path}
                >
                  <ListItemButton
                    sx={{
                      background: location?.pathname === path
                        ? "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)"
                        : "transparent",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "44px",
                      }}
                    >
                      {location?.pathname === path ? (
                        <img
                          src={active}
                          style={{
                            boxSizing: "border-box",
                            height: "30px",
                            width: "30px",
                          }}
                          alt=""
                          srcSet=""
                        />
                      ) : (
                        <img
                          src={icon}
                          style={{
                            boxSizing: "border-box",
                            height: "30px",
                            width: "30px",
                          }}
                          alt=""
                          srcSet=""
                        />
                      )}{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={title}
                      sx={{
                        color: location?.pathname === path
                          ? "#000000"
                          : "#ADADAD",
                        ".MuiTypography-root": {
                          fontWeight: "500",
                          fontSize: "18px",
                          fontFamily: "Outfit",
                        }

                      }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            )
          })}
        </List>
      </Box>
      {/* Logout Button */}
      <Button
        type="button"
        sx={{
          width: "100%",
          background:
            "transparent",
          padding: "8px 12px",
          fontWeight: "500",
          fontSize: "18px",
          color: "#737373",
          textTransform: "capitalize",
          position: "absolute",
          bottom: "0px",
          left: "0px",
          borderRadius: "0px",
          "&:hover": {
            background:
              "transparent",
            color: "#737373",
          },

        }}
        onClick={() => handleLogout()}
      >
        <img src={LOGOUT} alt="" /> Logout
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          height: "50px",
          boxSizing: "border-box",
        }}
      >
        {/* <LogoutModal /> */}
      </Box>

      <Divider />
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          //   width: { sm: `calc(100% - ${drawerWidth}px)` },
          //   ml: { sm: `${drawerWidth}px` },
          display: { sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box sx={{ display: { sm: "none" } }}>
          <Toolbar />
        </Box>
        <Box
        // m={
        //   // location.pathname === PathList.Notification
        //   //   ? "0"
        //   //   : location.pathname === PathList.FAQs
        //   //   ? "0"
        //   //   : location.pathname === PathList.Support
        //   //   ? "0"
        //   //   : location.pathname === PathList.AppIssue
        //   //   ? "0"
        //   //   :
        //   "1rem 1rem 0 1.8rem"
        // }
        >
          {element}
        </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;
