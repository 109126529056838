import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import { adminLayoutRoute } from "../../Routes/Routes";
import Middleware from "../Middleware/Middleware";
import StateStoreProvider from "../Middleware/StateContext";

const AdminLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userCheck = JSON.parse(localStorage.getItem("TRANSFORMATIONAL_RUNNER"));
    if (userCheck?.role !== 3 && userCheck?.role !== 2) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <StateStoreProvider>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <Routes>
          {adminLayoutRoute.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <Middleware>
                  {route.sidebar ? (
                    <>
                      <Sidebar />
                      <Box
                        sx={{
                          width: "calc(100% - 280px)",
                          height: "100vh",
                          overflow: "auto",
                        }}
                      >
                        {route.component}
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{ width: "100%", height: "100vh", overflow: "auto" }}
                    >
                      {route.component}
                    </Box>
                  )}
                </Middleware>
              }
            />
          ))}
        </Routes>
      </Box>
    </StateStoreProvider>

  );
};

export default AdminLayout;
