import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import CustomThumbUpload from '../../../Components/CustomFileUpload/UploadImage';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import Constant from '../../../Utils/Constant';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { AUTH_LOGIN, DATABASE } from '../../../Config/firebase';
import { doc, setDoc } from 'firebase/firestore';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import { toast } from 'react-toastify';

const AddSubAdmin = (props) => {
    const { open, onClose, typeCheck, handleOnClose, handleOnSubmit } = props;
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            userName: "",
            email: "",
            password: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object().shape({
            userName: Yup.string().required("User name is required"),
            email: Yup.string().required("Email Address is required").matches(Constant.REGEX.EMAIL, "Please Enter Valid Email Address"),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            if (isLoading) {
                return;
            } else {
                try {
                    const userCredential = await createUserWithEmailAndPassword(AUTH_LOGIN, values.email, values.password);
                    await setDoc(doc(DATABASE, "subAdmins", userCredential.user.uid), {
                        userName: values.userName,
                        email: values.email,
                        role: 1,
                        createdAt: new Date(),
                    });
                    if (userCredential && userCredential?.user?.accessToken) {
                        const formData = new FormData();
                        formData.append('auth_token', userCredential?.user?.uid);
                        formData.append('user_name', values.userName);
                        formData.append('email', values.email)
                        const response = await AxiosInstance.post(APILIST.ADD_SUB_ADMIN, formData);
                        if (response && response?.data?.s) {
                            toast.success("SubAdmin Added Sucessfully.");
                            resetForm();
                            handleOnSubmit()
                        }
                    }

                } catch (error) {
                    toast.error(error);
                } finally {
                    setIsLoading(false);

                }
            }
        },
    });
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    padding: '20px 30px',
                    height: "600px",
                    overflow: "scroll",
                    "::-webkit-scrollbar": { width: "0px", height: '0px' }
                },
                "& .MuiDialogContent-root": {
                    overflowY: "initial",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }
            }}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', color: '#101010', fontFamily: "Outfit" }}>{typeCheck ? "Update" : "Add"} SubAdmin</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <IconButton sx={{
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#101010',
                                borderRadius: '8px',
                                "&:hover": {
                                    backgroundColor: '#101010',
                                    color: '#fff',
                                }
                            }} onClick={handleOnClose}>
                                <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    padding: '20px 0px 0px 0px',
                }}>
                    <Grid spacing={2} container>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="User Name"
                                type="text"
                                placeholder="Enter User Name"
                                name="userName"
                                value={formik.values.userName}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.userName && formik.errors.userName}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.userName ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Email Address"
                                type="text"
                                placeholder="Enter Email Address"
                                name="email"
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.email && formik.errors.email}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.email ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Password"
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formik.values.password}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.password && formik.errors.password}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.password ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Confirm Password"
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={formik.values.confirmPassword}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.confirmPassword ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomSubmitButton
                                btnTitle={typeCheck ? "Update" : "Add"}
                                loading={isLoading}
                                type="submit"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Dialog>
    );
};

export default AddSubAdmin;