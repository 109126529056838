import React, { useState } from "react";
import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const CustomTextField = ({
    label,
    type,
    placeholder,
    name,
    value,
    onBlur,
    onKeyUp,
    errors,
    onChange,
    isLoading,
    rows,
    multiline,
    min,
    inputMode,
    pattern,
    onKeyDown,
    sx = {},
    disabled,
    style = {},
    max,
    required
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Typography
                sx={{
                    color: "#fff",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: { xs: "16px", sm: "18px" },
                    fontFamily: "Outfit",
                    ...style,
                }}
            >
                {label}
                {required && (
                    <span style={{
                        color: "red"
                    }}>*</span>)}
            </Typography>
            {isLoading ? (
                <Skeleton height={60} />
            ) : (
                <TextField
                    sx={{
                        width: "100%",
                        mt: "10px",
                        "& .MuiOutlinedInput-notchedOutline ": {
                            outline: "none",
                            border: errors ? "1px solid red" : "none",
                        },
                        "& .MuiInputBase-root": {
                            borderRadius: "20px",
                            border: "1px solid #D0BCFF",
                            color: "white",
                            fontSize: "16px",
                            background: '#14131E',
                            fontFamily: "Outfit"
                        },
                        ...sx,
                    }}
                    type={type === "password" ? (showPassword ? "text" : "password") : type}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    onKeyUp={onKeyUp}
                    multiline={multiline}
                    rows={rows}
                    onKeyDown={onKeyDown}
                    inputMode={inputMode}
                    min={min}
                    pattern={pattern}
                    disabled={disabled}
                    InputProps={{
                        maxLength: max,
                        endAdornment: type === "password" ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff sx={{ color: '#fff' }} /> : <Visibility sx={{ color: '#fff' }} />}
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }}
                />
            )}
            {errors ? (
                <Typography
                    sx={{
                        color: "red",
                        fontWeight: "400",
                        fontSize: { xs: "14px", sm: "14px" },
                        marginTop: "5px",
                        paddingLeft: '10px'
                    }}
                >
                    {errors}
                </Typography>
            ) : null}
        </Box>
    );
};

export default CustomTextField;
