import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import TermsConditions from "./TermsConditions.jsx";
import AboutUs from "./AboutUs";
import PrivecyPolicy from "./PrivacyPolicy";
import Faq from "./Faq.js";
import CookiePolicy from "./CookiePolicy.jsx";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={'20px 0px 0px 0px'}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ContentManagement = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", padding:'15px'}}>
      <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "500", paddingBottom:'10px' }}>
          Content Management
        </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="content management tabs"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              "& .MuiTab-root": {
                fontSize: { xs: "12px", sm: "14px", md: "16px" },
              },
            }}
          >
            <Tab label="Terms & Conditions" {...a11yProps(0)} sx={{
              textTransform:'capitalize',
             
            }}/>
            <Tab label="Privacy Policy" {...a11yProps(1)}sx={{
              textTransform:'capitalize',
            
            }} />
            <Tab label="Cookie Policy" {...a11yProps(2)}sx={{
              textTransform:'capitalize',
            
            }} />
            <Tab label="About Us" {...a11yProps(3)} sx={{
              textTransform:'capitalize',
            }}/>
            <Tab label="Faq" {...a11yProps(4)} sx={{
              textTransform:'capitalize',
            }}/>
          </Tabs>
          <TabPanel value={value} index={0}>
            <TermsConditions />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PrivecyPolicy />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CookiePolicy />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AboutUs />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Faq />
          </TabPanel>
      </Box>
    </>
  );
};

export default ContentManagement;
