import Dashboard from "../../Assets/Images/Common/dashboard_icon.svg";
import USER from "../../Assets/Images/Common/Users.png";
import VIDEO from "../../Assets/Images/Common/video_icon.svg";
import CATEGORY from "../../Assets/Images/Common/category_icon.svg";
import PARTNER from "../../Assets/Images/Common/partner_icon.svg";
import CONTENT from "../../Assets/Images/Common/content_icon.svg";
import CONTACT from "../../Assets/Images/Common/contact_icon.svg";
import DashboardA from "../../Assets/Images/Common/DashboardA.png";
import USERA from "../../Assets/Images/Common/UsersA.png";
import VIDEOA from "../../Assets/Images/Common/VideoA.png";
import CATEGORYA from "../../Assets/Images/Common/VideoCategoryA.png";
import PARTNERA from "../../Assets/Images/Common/PartnerA.png";
import CONTENTA from "../../Assets/Images/Common/ContentA.png";
import CONTACTA from "../../Assets/Images/Common/ContactUsA.png";
import RUNNER_PLAN from "../../Assets/Images/Common/runner_plan.svg";
import RUNNER_PLAN_A from "../../Assets/Images/Common/runner_plan_A.svg";
import BLOG_ICON from "../../Assets/Images/Common/blog_icon.png";
import BLOG_ICON_A from "../../Assets/Images/Common/blog_icon_a.png";
import EVENT_A from "../../Assets/Images/Common/eventA.svg";
import EVENT from "../../Assets/Images/Common/event.svg";
export const NavMenuList = [
  {
    title: "Dashboard",
    path: '/admin/dashboard',
    icon: Dashboard,
    active: DashboardA,
  },
  {
    title: "User Management",
    path: '/admin/user',
    icon: USER,
    active: USERA,
  },
  {
    title: "Runner Plan",
    path: '/admin/runner-plan',
    icon: RUNNER_PLAN,
    active: RUNNER_PLAN_A,
  },
  {
    title: "Workout Plan",
    path: '/admin/workout',
    icon: RUNNER_PLAN,
    active: RUNNER_PLAN_A,
  },
  {
    title: "Challenges & Events",
    path: '/admin/challenges-events',
    icon: EVENT,
    active: EVENT_A,
  },
  {
    title: "Video Management",
    path: '/admin/video',
    icon: VIDEO,
    active: VIDEOA,
  },
  {
    title: "Video Category",
    path: '/admin/category',
    icon: CATEGORY,
    active: CATEGORYA,
  },
  {
    title: "Partner Managment",
    path: '/admin/partner',
    icon: PARTNER,
    active: PARTNERA,
  },
  {
    title: "Offer Managment",
    path: '/admin/offer',
    icon: PARTNER,
    active: PARTNERA,
  },
  {
    title: "Blog Management",
    path: '/admin/blog',
    icon: BLOG_ICON,
    active: BLOG_ICON_A,
  },
  {
    title: "Blog Category",
    path: '/admin/blog-category',
    icon: BLOG_ICON,
    active: BLOG_ICON_A,
  },
  {
    title: "Content Management",
    path: '/admin/content',
    icon: CONTENT,
    active: CONTENTA,
  },
  {
    title: "User Queries",
    path: '/admin/contact-us',
    icon: CONTACT,
    active: CONTACTA,
  },
  {
    title: "Notifications",
    path: '/admin/notifications',
    icon: CONTACT,
    active: CONTACTA,
  },
  {
    title: "Sub Admin",
    path: '/admin/subAdmin',
    icon: USER,
    active: USERA,
  },
  {
    title: "Support",
    path: '/admin/chat',
    icon: USER,
    active: USERA,
  },
];
