export const planLevelList = [
    {
        id: '1',
        name: "beginner",
        label: "beginner"
    },
    {
        id: '2',
        name: "intermediate",
        label: "intermediate"
    },
    {
        id: '3',
        name: "advanced",
        label: "advanced"
    },
]